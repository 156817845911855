import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-unpaid-corp-list',
  templateUrl: './unpaid-corp-list.component.html',
  styleUrls: ['./unpaid-corp-list.component.scss'],
})
export class UnpaidCorpListComponent implements OnInit {
  public AcquiredCompanyName = '';
  public customCheckbox: boolean = true;
  public ClosedDate;
  public today
  public unpaid_only_flg = '';
  public back_button: boolean = false;

  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public pages1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-unpaid-list';
    this.widthChanger('main-header', 360);
    this.spinner.show();
    this.today = new Date();
    this.ClosedDate = this.datepipe.transform(this.today, 'yyyy/MM/dd');
    this.unpaid_only_flg = this.customCheckbox ? '1' : '0';
    this.rest.Get_WFAccount(this.data.uuid).subscribe(
      (res) => {
        console_log(res);
        if (res.data.management_company_code == 'HC01') {
          this.back_button = true;
        } else {
          this.data.AcquiredCode = res.data.management_company_code;
          this.data.AcquiredName = res.data.management_company_name;
        }
        const body = {
          data:{
            user_uuid: this.data.uuid,
            acquired_code: this.data.AcquiredCode,
            company_name: this.AcquiredCompanyName,
            unpaid_only_flg: this.unpaid_only_flg
          }
        };
        console_log(body);
        this.rest.Get_UnpaidCorp(this.data.uuid, this.data.AcquiredCode, this.AcquiredCompanyName, this.unpaid_only_flg).subscribe(
          (res) => {
            this.filteredItems = res.data.unpaid_list;
            console_log(res);
            this.currentIndex = 1;
            this.pageStart = 1;
            this.pages = 6;

            this.pageNumber = parseInt(
              '' + this.filteredItems.length / this.pageSize
            );
            if (this.filteredItems.length % this.pageSize != 0) {
              this.pageNumber++;
            }
            if (this.pageNumber < this.pages) {
              this.pages = this.pageNumber;
            }
            this.refreshItems();
            this.spinner.hide();
          },
          (err) => {
            console_log(err);
            this.spinner.hide();
            this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
          }
        );
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  parse(e) {
    return parseFloat(e).toLocaleString('en');
  }
  Search(){
    this.spinner.show();
    this.unpaid_only_flg = this.customCheckbox ? '1' : '0';
    const body = {
      data:{
        user_uuid: this.data.uuid,
        acquired_code: this.data.AcquiredCode,
        company_name: this.AcquiredCompanyName,
        unpaid_only_flg: this.unpaid_only_flg
      }
    };
    console_log(body);
    this.rest.Get_UnpaidCorp(this.data.uuid, this.data.AcquiredCode, this.AcquiredCompanyName, this.unpaid_only_flg).subscribe(
      (res) => {
        this.filteredItems = res.data.unpaid_list;
        console_log(res);
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  Unpaidmembers(corporation_no){
    this.router.navigate(['/wf-unpaid-user-list', { corporation_no:  corporation_no}]);
  }
  back(){
    this.router.navigate(['/wf-unpaid-list']);
  }
  Detail(uuid){
    this.data.backButton = '/wf-unpaid-corp-list';
    this.router.navigate(['/wf-contract-list-detail', {uuid: uuid}]);
  }
}