import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/ja';
import { REGION, S3_BUCKET, S3_FOLDER_PATH, USER_POOL_ID, IDENTITY_POOL_ID } from 'src/environments/environment';
import * as AWS from 'aws-sdk';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class ModifyEditComponent implements OnInit {

  public documentpdf;
  public uuid;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post;
  public Staff_Position;
  public Users;
  public History_Flg;
  public notKana = false;
  public notFirstnameKana = false;
  public notLastnameKana = false;

  public Corporate_Status;
  public Corporation_No;
  public Acquired_Company_Code;
  public Acquired_Company_Name;
  public Application_Date;
  public Application_Time;
  public Judge_Status;
  public Fees;
  public Note;
  public Mail;
  public Company_Number;
  public selectedRadio;
  public AcquiredInfo = [];
  public mail_send_flg;

  public Message_Code_Reg;
  public Message_Code_Pdf;

  butDisabled = false;
  butDisabled1 = true;
  public pdffile_name;
  public pdffile_name_s3;
  public StartDate = '';
  public EndDate = '';
  public AppDate_1;
  public AppDate_2 = '';
  public acquired_data = '';
  public today;
  public PDF_URL;
  public pdffile;
  public FileName;
  public fileType;
  public pdffile_base64;
  public fileType_base64;
  public ContractsFLG = 0;
  public health_code = '';
  public paid_form;
  public Promo_Code1 = '';
  public Promo_Code2 = '';
  public Fees1;
  public Fees2;
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    console_log(this.pdffile);
    this.spinner.show();
    this.rest.Get_ApplicationsId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        console_log(res)
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.History_Flg = res.data.history_flg;
        this.Corporation_No = res.data.corporation_no;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.Acquired_Company_Name = res.data.acquired_name;
        this.StartDate = this.datepipe.transform(res.data.start_date, 'yyyy/MM/dd');
        this.Mail = res.data.mail_address;
        this.Application_Time = res.data.app_time;
        this.Judge_Status = res.data.judge_state;
        this.Fees = res.data.fees;
        this.Note = res.data.note;
        this.Users = res.data.users;
        this.ContractsFLG = res.data.contracts_flg;
        this.health_code = res.data.health_code;
        this.paid_form = res.data.paid_form;
        this.Promo_Code1 = res.data.promotion_code1;
        this.Promo_Code2 = res.data.promotion_code2;
        this.Fees1 = res.data.fees1;
        this.Fees2 = res.data.fees2;
        let dateString = this.StartDate
        let newDate = new Date(dateString);
        this.AppDate_1 = newDate;
        this.acquired_data = this.Acquired_Company_Code + ':' + this.Acquired_Company_Name;
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
    this.widthChanger('main-header', 942);
    this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
      (res) => {
        this.AcquiredInfo = res.data.AcquiredInfo;
      },
      (err) => { }
    );
  }
  onKey(data) {
    this.rest.ZipCode(data).subscribe((res) => {
      if (res.results) {
        this.Address_1 = res.results[0].address1 + res.results[0].address2 + res.results[0].address3;
      }
    });
  }
  selectCorporation(id) {
    this.Corporate_Status = id;
  }
  selectHistory(id) {
    this.History_Flg = id;
  }
  select() {
    if (this.AcquiredInfo) {
      for (var i = 0; i <= this.AcquiredInfo.length; i++) {
        if (this.AcquiredInfo[i].acquired_code === this.Acquired_Company_Code) {
          this.Acquired_Company_Name = this.AcquiredInfo[i].acquired_name;
        }
      }
    }
  }

  back(id) {
    if (id == 1) {
      window.IMAGE_RESULT = '';
      window.PDF_RESULT = '';
      this.pdffile = '';
      this.router.navigate(['/wf-contract-list-detail', { uuid: this.uuid }]);
    } else {
      document.getElementById('confirm').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      this.widthChanger('main-header', 942);
    }
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    let Number = /^-?\d*$/;
    if (this.Users == null || this.Users == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT')
        .subscribe((res) => (errTxt = res));
    } else if (this.Users != "" && !Number.test(this.Users)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notFirstnameKana && this.Name_Kana !== null && this.Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name_Kana == null || this.Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notLastnameKana && this.Last_Name_Kana !== null && this.Last_Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name_Kana == null || this.Last_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Name == null || this.Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    // if (this.Mail == null || this.Mail == '') {
    //   err = true;
    //   this.translate
    //     .get('COMPANY_REGISTER_VALIDATION.ERROR_MAIL')
    //     .subscribe((res) => (errTxt = res));
    // }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    // if (this.health_code == null || this.health_code == '') {
    //   err = true;
    //   this.translate
    //     .get('COMPANY_REGISTER_VALIDATION.ERROR_INSURANCE_CARD')
    //     .subscribe((res) => (errTxt = res));
    // }
    if (this.health_code != "" && !Number.test(this.health_code)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_INSURANCE_CARD_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.TelePhone == null || this.TelePhone == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE')
        .subscribe((res) => (errTxt = res));
    } else if (this.TelePhone != "" && !Number.test(this.TelePhone)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Address_2 == null || this.Address_2 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_2')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Address_1 == null || this.Address_1 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_1')
        .subscribe((res) => (errTxt = res));
    }

    if (this.Post_Code == null || this.Post_Code == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE')
        .subscribe((res) => (errTxt = res));
    } else if (this.Post_Code != "" && !Number.test(this.Post_Code)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notKana === true && this.Company_Name_Kana !== null && this.Company_Name_Kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_NOT_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Company_Name_Kana == null || this.Company_Name_Kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Company_Name == null || this.Company_Name == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.StartDate == null || this.StartDate == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_START_DATE')
        .subscribe((res) => (errTxt = res));
    }
    if (this.pdffile != undefined) {
      if (this.fileType != 'pdf') {
        err = true;
        this.translate
          .get('COMPANY_REGISTER_VALIDATION.ERROR_PLEASE_UPLOAD_THE_PDF_FILE')
          .subscribe((res) => (errTxt = res));
        this.pdffile = '';
      }
    }
    if (this.pdffile_base64 != undefined) {
      if (this.fileType_base64 != 'pdf') {
        err = true;
        this.translate
          .get('COMPANY_REGISTER_VALIDATION.ERROR_PLEASE_UPLOAD_THE_PDF_FILE')
          .subscribe((res) => (errTxt = res));
        this.pdffile_base64 = '';
      }
    }
    if (this.Fees1 == null || this.Fees1 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FEES')
        .subscribe((res) => (errTxt = res));
    }
    
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    
    return true;
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;

      header.style.minWidth = width.toString() + 'px';
    }
  }
  selectPDF() {
    const input: HTMLElement = document.getElementById('pdffile');
    input.click();
  }
  selectPDFS3() {
    const input: HTMLElement = document.getElementById('pdffileS3');
    input.click();
  }
  upload(event: Event) {
    const self: ModifyEditComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      var pdf_string = reader.result.toString();
      var pdf_binary = pdf_string.split('data:application/pdf;base64,');
      window.IMAGE_RESULT = pdf_binary[1];
    };
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.pdffile_base64 = element.files[0];
      this.pdffile_name = element.files[0].name;
      this.fileType_base64 = this.pdffile_name .slice(this.pdffile_name .length - 3)
    }
    element.value = '';
  }
  uploadS3(event: Event) {
    const self: ModifyEditComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      var pdf_string = reader.result.toString();
      var pdf_binary = pdf_string.split('data:application/pdf;base64,');
      window.PDF_RESULT = pdf_binary[1];
    };
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.pdffile = element.files[0];
      this.pdffile_name_s3 = element.files[0].name;
      this.fileType = this.pdffile_name_s3 .slice(this.pdffile_name_s3 .length - 3)
    }
    element.value = '';
  }
  async uploadPDF() {
    let result = '';
      this.spinner.show();
      const credential = {
        IdentityPoolId: IDENTITY_POOL_ID,
        Logins: {}
      }
      credential.Logins['cognito-idp.' + REGION + '.amazonaws.com/' + USER_POOL_ID] = localStorage.getItem('token');
      AWS.config.update({
        region: REGION,
        credentials: new AWS.CognitoIdentityCredentials(credential)
      });
      const s3 = new AWS.S3({
        apiVersion: "2012-10-17",
        params: {
          Bucket: S3_BUCKET
        }
      });
      const uploadFileKey = S3_FOLDER_PATH + this.uuid + '.pdf';
      this.FileName = this.uuid + '.pdf';
      const s3Params = {
        ContentType: 'application/pdf',
        Body: this.pdffile,
        Bucket: S3_BUCKET,
        Key: uploadFileKey
      };
      let uploadResult = null;
      try {
        uploadResult = await s3.putObject(s3Params).promise();
      } catch (error) { }
      if (uploadResult != null) {
        let upRes = await this.rest.Update_ContractsFlg(this.data.uuid, this.uuid).toPromise();
        if(upRes.code) {
          result = upRes.code;
        } else {
          result = upRes.error.error.code;
        }
      } else {
        this.spinner.hide();
        result = 'ERR_PDF_S3';
      }
      return result;
  }
  input() {
    this.StartDate = this.datepipe.transform(this.AppDate_1, 'yyyy/MM/dd');
    this.EndDate = this.datepipe.transform(this.AppDate_2, 'yyyy/MM/dd');

    var acquired_data = this.acquired_data.split(":");
    this.Acquired_Company_Code = acquired_data[0];
    this.Acquired_Company_Name = acquired_data[1];
    if (this.dataValidation() == false) {
      return true;
    } else {
      document.getElementById('confirm').style.display = 'block';
      document.getElementById('edit').style.display = 'none';
      document.getElementById('confirm').classList.remove('row1');
      document.getElementById('edit').classList.add('row');
      this.widthChanger('main-header', 752);
    }
  }
  async confirm() {
    var acquired_data = this.acquired_data.split(":");
    this.Acquired_Company_Code = acquired_data[0];
    this.Acquired_Company_Name = acquired_data[1];
    // this.StartDate = this.datepipe.transform(this.AppDate_1, 'yyyy-MM-dd');
    // this.EndDate = this.datepipe.transform(this.AppDate_2, 'yyyy-MM-dd');
    if (this.EndDate == null) {
      this.EndDate = '';
    }

    if (this.health_code == null) {
      this.health_code = '';
    }
    var Users: string = this.Users.toString();
    this.spinner.show();
    this.rest.Update_Contracts(
      this.data.uuid,
      this.uuid,
      this.StartDate,
      this.EndDate,
      this.Corporate_Status,
      this.Company_Name,
      this.Company_Name_Kana,
      this.Post_Code,
      this.Address_1,
      this.Address_2,
      this.Address_3,
      this.TelePhone,
      this.Mail,
      this.Last_Name,
      this.Name,
      this.Last_Name_Kana,
      this.Name_Kana,
      this.Staff_Post,
      this.Staff_Position,
      this.Corporation_Form,
      Users,
      this.Acquired_Company_Code,
      this.History_Flg,
      '0',
      this.health_code
    ).subscribe(
      async (res) => {
        console_log(res);
        if (window.IMAGE_RESULT) {
          this.rest.Upload_Regcertificates(this.uuid, this.data.uuid, window.IMAGE_RESULT).subscribe(
            (res) => {
              if (window.PDF_RESULT) {
                window.PDF_RESULT = '';
                this.uploadPDF().then(async res => {
                    console_log(res);
                    if (res == 'S000001') {
                      this.spinner.hide()
                      this.router.navigate(['/wf-contract-modify-comp']);
                    } else if(res == 'ERR_PDF_S3') {
                      this.spinner.hide()
                      this.router.navigate(['/wf-contract-err', { code: 'ERR_PDF_S3' }]);
                    } else {
                      this.spinner.hide()
                      this.router.navigate(['/wf-contract-err', { code: res }]);
                    }
                  }
                );
              } else {
                this.spinner.hide()
                this.router.navigate(['/wf-contract-modify-comp']);
              }
            },
            (err) => {
              if (err.error.error) {
                this.spinner.hide();
                this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
              } else {
                this.spinner.hide();
                this.router.navigate(['/wf-contract-err', { code: 'ERR_PDF' }]);
              }
            }
          );
        } else {
          if (window.PDF_RESULT) {
              window.PDF_RESULT = '';
              this.uploadPDF().then(async res => {
                  console_log(res);
                  if (res == 'S000001') {
                    this.spinner.hide()
                    this.router.navigate(['/wf-contract-modify-comp']);
                  } else if(res == 'ERR_PDF_S3') {
                    this.spinner.hide()
                    this.router.navigate(['/wf-contract-err', { code: 'ERR_PDF_S3' }]);
                  } else {
                    this.spinner.hide()
                    this.router.navigate(['/wf-contract-err', { code: res }]);
                  }
                }
              );
          } else {
            this.spinner.hide()
            this.router.navigate(['/wf-contract-modify-comp']);
          }
        }
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );

  }
  onKeyKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notKana = true;
    } else {
      this.notKana = false;
    }
  }

  onKeyLastNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notLastnameKana = true;
    } else {
      this.notLastnameKana = false;
    }
  }

  onKeyNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notFirstnameKana = true;
    } else {
      this.notFirstnameKana = false;
    }
  }

  isKana(ch) {
    let k = 0;
    for (let i = 0; i < ch.length; i++) {
      if (ch[i] >= "\u30a0" && ch[i] <= "\u30ff") {
        k++;
      }
    }
    if (k == ch.length) {
      return true;
    }
    return false;
  }
  pdf_download() {
    if (window.IMAGE_RESULT) {
      var pdfWindow = window.open("");
      pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(window.IMAGE_RESULT) + "'></iframe>");
    } else {
      this.rest.Download_Regcertificates(this.data.uuid, this.uuid).subscribe(
        (res) => {
          if (res.code == 'S000001') {
            this.documentpdf = res.data.s3url;
            window.open(this.documentpdf, '_blank', '');
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
        }
      );
    }
  }
  pdf_download_s3() {
    if (window.PDF_RESULT) {
      var pdfWindow = window.open("");
      pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(window.PDF_RESULT) + "'></iframe>");
    } else {
      this.rest.Download_Contracts(this.data.uuid, this.uuid).subscribe(
        (res) => {
          if (res.code == 'S000001') {
            window.open(res.data.s3url, '_blank', '');
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
        }
      );
    }
  }
  @ViewChild('date1') date1: ElementRef;
  @ViewChild('date2') date2: ElementRef;
  options: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy/MM/dd', // date format to display in input
    formatTitle: 'yyyy' + '年' + 'MM' + '月',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'bottom',
    inputClass: '', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#E10800', // in case you customize you theme, here you define scroll bar color
  };
  resetDate(id) {
    if (id == 1) {
      if (this.AppDate_1 != '') {
        this.date1['displayValue'] = '';
        this.AppDate_1 = '';
        if (this.AppDate_1 == '') {
          document.getElementById('clear1').style.display = 'none';
        }
      }
    } else if (id == 2) {
      if (this.AppDate_2 != '') {
        this.date2['displayValue'] = '';
        this.AppDate_2 = '';
        if (this.AppDate_2 == '') {
          document.getElementById('clear2').style.display = 'none';
        }
      }
    }
  }
  Calendar(id) {
    if (id == 1) {
      if (this.AppDate_1 != '') {
        // document.getElementById('clear1').style.display = 'block';
      } else {
        // document.getElementById('clear1').style.display = 'none';
      }
    } else {
      if (this.AppDate_2 != '') {
        document.getElementById('clear2').style.display = 'block';
      } else if (this.AppDate_2 == '') {
        document.getElementById('clear2').style.display = 'none';
      }
    }
  }
}

declare global {
  interface Window {
    IMAGE_RESULT?: string;
    PDF_RESULT?: string;
  }
}
