import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class AnnEditComponent implements OnInit {
  public Date;
  public Contents;
  public DatePublic;
  public TimePublic;
  public selectedRadio = '表示する';
  public visible;
  public invisible;
  constructor(private router: Router, public data: DataProvider) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-announcement';
  }

  register() {
    document.getElementById('confirm').style.display = 'block';
    document.getElementById('edit').style.display = 'none';
  }
  back(id) {
    if (id == 1) {
      this.router.navigate(['/wf-announcement']);
    } else {
      document.getElementById('confirm').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
    }
  }
  finish() {
    this.router.navigate(['/wf-announcement-edit-comp']);
  }
  selectedRadios(id) {
    this.selectedRadio = id;
  }
}
