// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const IDENTITY_POOL_ID ='ap-northeast-2:890f029f-9ab7-48b8-a752-6ec49437f9e6';
export const USER_POOL_ID = 'ap-northeast-2_FHBwbn7i4';
export const CLIENT_ID = '2bnt99lvt2drsicnjfsltfhulj';
export const REGION = 'ap-northeast-2';
export const MANUAL_PDF_HC = 'https://test-console.joyfit-service.jp/pdf/console_manual_HC.pdf';
export const MANUAL_PDF_FC = 'https://test-console.joyfit-service.jp/pdf/console_manual_FC.pdf';

export const API_URL ='https://mba82pz5a3.execute-api.ap-northeast-2.amazonaws.com/test';

// export const PT_API_URL = 'https://test-api.wf-datahub.com/mainapi/casio';
// export const PT_HASH = 'qYGEsExqaRT9OwUqDsX9bsRp6KoIeuyIO1fXKSlyQRbsikuhbWk7feKEnK8dF25s';
// export const BEA_POINT_HASH = 'bee8Otu8ahth3shi0iezuP0Eegu9xa8dieTicae8aimoosa3aec5reiceem1rohH';
// export const BEA_POINT_API_URL = 'https://beapoint.joyfit-service.jp/api';

export const S3_BUCKET = 'test-corporation.joyfit-service.jp';
export const S3_FOLDER_PATH = 'pdf/Contracts/';
