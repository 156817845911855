import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-mail-comp',
  templateUrl: './mail-comp.component.html',
  styleUrls: ['./mail-comp.component.scss'],
})
export class MyAccountMailCompComponent implements OnInit, OnDestroy {
  public crypto_uuid = '';
  public crypto_pattern = '';
  public logoUrl
  constructor(
    private renderer: Renderer2,
    public data: DataProvider,
    public router: Router,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'change-password-page');
    this.spinner.show();
    this.crypto_uuid = this.route.snapshot.queryParams["id"];
    this.crypto_pattern = this.route.snapshot.queryParams["cpn"];
    localStorage.setItem('token',this.crypto_uuid);
    console_log(this.crypto_uuid);
    console_log(this.crypto_pattern)
    this.rest.Update_WFCognitoMail(this.crypto_uuid, this.crypto_pattern).subscribe(
      (res) => {
        console_log(res);
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-myaccount-mail-err', {code: err.error.error.code}]);
      }
    )
  }
  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'mail-comp'
    );
  }
}
