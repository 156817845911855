<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ANNOUNCEMENT.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="finish text-center">
      {{ 'DELETE_ANNOUNCEMENT.FINISH_TEXT_1' | translate }}<br>{{ 'DELETE_ANNOUNCEMENT.FINISH_TEXT_2' | translate }}
    </div>
    <div class="finish2 text-center">
      {{ 'DELETE_ANNOUNCEMENT.FINISH_TEXT_3' | translate }}
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish2">
          <a (click)="notification()" class="btn-grey">
            {{ 'DELETE_ANNOUNCEMENT.FINISH_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>