<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'CONTRACT_APP_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body search-form-top">
    <!-- Input -->
    <div class="row">
      <div class="input-group company-name">
        <input
          type="text"
          class="form-control"
          id="CompanyName"
          [(ngModel)]="CompanyName"
          placeholder="{{
            'CONTRACT_APP_LIST.COMPANY_NAME_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!CompanyName" class="icon" src="assets/icons/book.svg" />
      </div>
      <div class="input-group telephone">
        <input
          type="text"
          class="form-control"
          id="Telephone"
          name="Telephone"
          [(ngModel)]="Telephone"
          placeholder="{{
            'CONTRACT_APP_LIST.TELEPHONE_PLACEHOLDER' | translate
          }}"
          maxlength="11"
          onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
        />
        <img *ngIf="!Telephone" class="icon" src="assets/icons/phone.svg" />
      </div>
      <div class="input-group date1">
        <ngx-datepicker [(ngModel)]="AppDate_1" [options]="options" (click)="Calendar(1)" #date1>
        </ngx-datepicker>
        <img id="calendar1" class="icon" src="assets/icons/date.svg"/>
        <img id="clear1" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="resetDate(1)"/>
      </div>
      <div class="interval">
        <h1 class="ftext">〜</h1>
      </div>
      <div class="input-group date">
        <ngx-datepicker [(ngModel)]="AppDate_2" [options]="options" (click)="Calendar(2)" #date2>
        </ngx-datepicker>
        <img id="calendar2" class="icon date-icon" src="assets/icons/date.svg"/>
        <img id="clear2" style="display: none;" class="icon clear-icon" src="assets/icons/x.svg" (click)="resetDate(2)"/>
      </div>
    </div>
    <div class="row">
      <div class="input-group acquired-company">
        <input list="brow" class="form-control" [(ngModel)]="acquired_data" placeholder="{{'CONTRACT_APP_LIST.ACQUIRED_COMPANY_NAME_PLACEHOLDER' | translate}}">
        <datalist id="brow" required name="acquired_data">
          <option class="acquired-font" *ngFor="let i of AcquiredInfo" value="{{i.acquired_code}}:{{ i.acquired_name }}">
          </option>
        </datalist>
        <img id="book" class="icon" src="assets/icons/openbookblack.svg"/>
      </div>
    </div>
    <!-- Check -->
    <div class="row checkbox-top">
      <!-- Check 1 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox1"
              [(ngModel)]="customCheckbox1"
            />
            <label for="customCheckbox1" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_1' | translate
            }}</label>
          </div>
        </div>
      </div>
      <!-- Check 2 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox2"
              [(ngModel)]="customCheckbox2"
            />
            <label for="customCheckbox2" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_2' | translate
            }}</label>
          </div>
        </div>
      </div>
      <!-- Check 3 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox3"
              [(ngModel)]="customCheckbox3"
            />
            <label for="customCheckbox3" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_3' | translate
            }}</label>
          </div>
        </div>
      </div>
      <!-- Check 4 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox4"
              [(ngModel)]="customCheckbox4"
            />
            <label for="customCheckbox4" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_4' | translate
            }}</label>
          </div>
        </div>
      </div>
      <!-- Check 5 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox5"
              [(ngModel)]="customCheckbox5"
            />
            <label for="customCheckbox5" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_5' | translate
            }}</label>
          </div>
        </div>
      </div>
      <!-- Check 6 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox6"
              [(ngModel)]="customCheckbox6"
            />
            <label for="customCheckbox6" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_6' | translate
            }}</label>
          </div>
        </div>
      </div>
      <!-- Check 7 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox7"
              [(ngModel)]="customCheckbox7"
            />
            <label for="customCheckbox7" class="custom-control-label ftext">{{
              'CONTRACT_APP_LIST.CHECKBOX_7' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-search-top">
      <a target="_blank" class="btn-search" (click)="Search()"
        >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
        <img
          class="search-icon"
          src="assets/icons/srch.svg"
        />
      </a>
      <!-- <label class="limit-500" id="limit500">{{
        'CONTRACT_APP_LIST.SEARCH_LIMIT_500' | translate
      }}</label> -->
    </div>
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">{{
              'COMMON.CASE_100' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: 800px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{ 'CONTRACT_APP_LIST.APPLY_DATE_COLUMN' | translate }}
                  <i (click)="order(1)" class="icon-arrow-up"></i>
                  <i (click)="order(2)" class="icon-arrow-down"></i>
                </th>
                <th class="ftext">
                  {{ 'CONTRACT_APP_LIST.COMPANY_NAME_COLUMN' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CONTRACT_APP_LIST.TELEPHONE_COLUMN' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CONTRACT_APP_LIST.MAIL_ADDRESS_COLUMN' | translate }}
                </th>
                <th class="ftext table-th4">
                  {{ 'CONTRACT_APP_LIST.CONTRACT_STATUS_COLUMN' | translate }}
                  <i (click)="order(3)" class="icon-arrow-up"></i>
                  <i (click)="order(4)" class="icon-arrow-down"></i>
                </th>
                <th class="ftext">
                  {{
                    'CONTRACT_APP_LIST.ACQUIRED_COMPANY_NAME_COLUMN' | translate
                  }}
                </th>
                <th class="ftext" *ngIf="wf_contract_apply_detail">
                  {{ 'CONTRACT_APP_LIST.DETAILS_COLUMN' | translate }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACT_APP_LIST.REGESTRATION_LAYER_COLUMN' | translate
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of items" class="text-center">
                <!-- App Date -->
                <td class="tbtext tdata-1">{{ list.app_date }}</td>
                <!-- company_name -->
                <td class="tbtext tdata-2">
                  {{ list.company_name }}
                </td>
                <td class="tbtext tdata-3">{{ list.tel }}</td>
                <td class="tbtext tdata-mail">{{ list.mail_address }}</td>
                <!-- judge state -->
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '01'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_1' | translate }}
                </td>
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '02'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_2' | translate }}
                </td>
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '03'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_5' | translate }}
                </td>
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '04'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_4' | translate }}
                </td>
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '05'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_3' | translate }}
                </td>
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '99'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_6' | translate }}
                </td>
                <td class="tbtext tdata-4" *ngIf="list.judge_state == '90'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_7' | translate }}
                </td>
                <td
                  class="tbtext tdata-4"
                  *ngIf="list.judge_state == '  '"
                ></td>
                <!-- Acquired_Company_Name -->
                <td class="tbtext tdata-5">
                  {{ list.acquired_name }}
                </td>
                <td class="tdata-6" *ngIf="wf_contract_apply_detail">
                  <a
                    (click)="details(list.uuid)"
                    class="btn-tb4"
                    >{{
                      'CONTRACTED_COMPANY_LIST.CONTRACT_APPLICATION_DETAIL_BUTTON'
                        | translate
                    }}</a
                  >
                </td>
                <td class="tdata-7">
                  <a
                    (click)="pdf_download(list.uuid)"
                    class="btn-tb5"
                    >{{
                      'CONTRACTED_COMPANY_LIST.DISPLAY_PDF_BUTTON' | translate
                    }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>          
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;</span
          >
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{
              disabled: currentIndex == pageNumber || pageNumber == 0
            }"
            (click)="nextPage()"
            class="next"
            >&raquo;</span
          >
        </div>
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>