<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'CONTRACTED_COMPANY_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body" style="margin-top: 50px; padding-left: 20px">
    <div class="title_text">
      <h1 class="ftext display">
        {{ 'CONTRACTED_COMPANY_LIST.CURRENT_CONTRACT_NUMBER' | translate }}
      </h1>
      <h1 class="ftext105 display">{{ContractCount}}</h1>
      <h1 class="ftext display" style="margin-left: 10px">
        {{ 'COMMON.CASE' | translate }}
      </h1>
    </div>
    <div class="row" style="margin-top: 20px">
      <div class="input-group" style="margin-left: 15px; width: 199px">
        <input
          type="text"
          class="form-control"
          id="CompanyName"
          [(ngModel)]="CompanyName"
          placeholder="{{
            'CONTRACT_APP_LIST.COMPANY_NAME_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!CompanyName" class="icon" src="assets/icons/book.svg" />
      </div>
      <div class="input-group" style="margin-left: 10px; width: 159px">
        <input
          type="tel"
          class="form-control"
          id="Telephone"
          [(ngModel)]="Telephone"
          maxlength="11"
          onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
          placeholder="{{
            'CONTRACT_APP_LIST.TELEPHONE_PLACEHOLDER' | translate
          }}"
        />
        <img *ngIf="!Telephone" class="icon" src="assets/icons/phone.svg" />
      </div>
      <div class="display1" style="margin-top: 10px; margin-left: 24px">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="check"
              [(ngModel)]="check"
            />
            <label for="check" class="custom-control-label ftext">{{
              'CONTRACTED_COMPANY_LIST.CONTRACTED' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="search-button">
        <a target="_blank" class="btn-search" (click)="Search()"
          >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
          <img
            class="search-icon"
            src="assets/icons/srch.svg"
          />
        </a>
      </div>
    </div> 
    <div class="row">
      <div class="input-group acquired-company">
        <input list="brow" class="form-control" [(ngModel)]="business_type" placeholder="{{'CONTRACT_APP_LIST.BUSINESS_TYPE_PLACEHOLDER' | translate}}">
        <datalist id="brow" required name="business_type">
          <option class="acquired-font" *ngFor="let i of BusinessType" value="{{ i.name }}">
          </option>
        </datalist>
        <img id="book" class="icon" src="assets/icons/openbookblack.svg"/>
      </div>
    </div>

    <!-- <label class="limit-500" id="limit500">{{
      'CONTRACT_APP_LIST.SEARCH_LIMIT_500' | translate
    }}</label> -->
    <!-- Radio -->
    <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(10)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'COMMON.CASE_10' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(20)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'COMMON.CASE_20' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r3"
              name="customRadio" (click)="selectedRadios(50)"
            />
            <label for="r3" class="custom-control-label ftext">{{
              'COMMON.CASE_50' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r4"
              name="customRadio" (click)="selectedRadios(100)"
            />
            <label for="r4" class="custom-control-label ftext">
              {{'COMMON.CASE_100' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: 1000px;">
            <thead>
              <tr class="text-center">
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.COMPANY_CODE_COLUMN' | translate
                  }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.COMPANY_NAME_COLUMN' | translate
                  }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.BUSINESS_TYPE_COLUMN' | translate
                  }}
                </th>
                <th class="ftext">
                  {{ 'CONTRACTED_COMPANY_LIST.TEL_COLUMN' | translate }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.MAIL_ADDRESS_COLUMN' | translate
                  }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.CONTRACT_FORM_COLUMN' | translate
                  }}
                </th>
                <th class="ftext" *ngIf="management_company_code == 'HC01'">
                  {{
                    'CONTRACTED_COMPANY_LIST.ACQUIRED_COMPANY_NAME_COLUMN' | translate
                  }}
                </th>
                <th class="ftext">
                  {{ 'CONTRACTED_COMPANY_LIST.USER_COUNT_COLUMN' | translate }}
                </th>
                <th class="ftext">
                  {{ 'CONTRACTED_COMPANY_LIST.APPLY_DATE_COLUMN' | translate }}
                  <a><i (click)="order(1)" class="icon-arrow-up"></i></a>
                  <a><i (click)="order(2)" class="icon-arrow-down"></i></a>
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.CONTRACT_DATE_COLUMN' | translate
                  }}
                  <a><i  (click)="order(3)" class="icon-arrow-up"></i></a>
                  <a><i  (click)="order(4)" class="icon-arrow-down"></i></a>
                </th>
                <th class="ftext">
                  {{ 'CONTRACTED_COMPANY_LIST.CANCEL_DATE_COLUMN' | translate }}
                  <a><i (click)="order(5)" class="icon-arrow-up"></i></a>
                  <a><i (click)="order(6)" class="icon-arrow-down"></i></a>
                </th>
                <th class="ftext" *ngIf="wf_contract_list_detail">
                  {{ 'CONTRACTED_COMPANY_LIST.DETAIL_COLUMN' | translate }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.REGISTRATION_COPY_COLUMN'
                      | translate
                  }}
                </th>
                <th class="ftext">
                  {{
                    'CONTRACTED_COMPANY_LIST.CONTRACT_COLUMN'
                      | translate
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let list of items" class="text-center">
                <td class="tbtext tdata1">{{ list.corporation_no }}</td>
                <td class="tbtext tdata2">
                  {{ list.company_name }}
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '01'">
                  農業・林業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '02'">
                  漁業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '03'">
                  鉱業，採石業，砂利，採取業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '04'">
                  建設業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '05'">
                  製造業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '06'">
                  電気・ガス・熱供給・水道業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '07'">
                  情報通信業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '08'">
                  運輸業，郵便業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '09'">
                  卸売業，小売業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '10'">
                  金融業，保険業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '11'">
                  不動産業，物品賃貸業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '12'">
                  学術研究，専門・技術サービス業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '13'">
                  宿泊業，飲食サービス業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '14'">
                  生活関連サービス業，娯楽業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '15'">
                  教育，学習支援業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '16'">
                  医療，福祉
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '17'">
                  複合サービス事業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '18'">
                  サービス業
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '19'">
                  公務
                </td>
                <td class="tbtext tdata3" *ngIf = "list.business_type == '20'">
                  分類不能の産業
                </td>
                <td class="tbtext tdata3">{{ list.tel }}</td>
                <td class="tbtext tdata4">
                  {{ list.mail_address }}
                </td>
                <!-- judge state -->
                <td class="tbtext tdata5" *ngIf="list.judge_state == '01' || list.judge_state == '04' || list.judge_state == '99' || list.judge_state == ''"></td>
                <td class="tbtext tdata5" *ngIf="list.judge_state == '02'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_2' | translate }}
                </td>
                <td class="tbtext tdata5" *ngIf="list.judge_state == '03'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_5' | translate }}
                </td>
                <td class="tbtext tdata5" *ngIf="list.judge_state == '05'">
                  {{ 'CONTRACT_APP_LIST.CHECKBOX_3' | translate }}
                </td>
                <td class="tbtext tdata5" *ngIf="list.judge_state == '90' && list.contract_start_date != '' && list.contract_end_date != ''">
                  {{ 'CONTRACTED_COMPANY_LIST.CONTRACT_FLG_1' | translate }}
                </td>
                <td class="tbtext tdata5" *ngIf="list.judge_state == '90' && list.contract_start_date != '' && list.contract_end_date == ''">
                  {{ 'CONTRACTED_COMPANY_LIST.CONTRACT_FLG_0' | translate }}
                </td>
                <td class="tbtext tdata2" *ngIf="management_company_code == 'HC01'">
                  {{ list.acquired_name }}
                </td>
                <td class="tbtext tdata9">{{ list.user_count }}</td>
                <td class="tbtext tdata6">{{ list.app_date }}</td>
                <td class="tbtext tdata6">{{ list.contract_start_date }}</td>
                <td class="tbtext tdata6">{{ list.contract_end_date }}</td>
                <td class="tbtext tdata7"  *ngIf="wf_contract_list_detail">
                  <a (click)="details(list)" class="btn-tb4">
                    {{ 'CONTRACTED_COMPANY_LIST.CONTRACT_APPLICATION_DETAIL_BUTTON'| translate}}
                  </a>
                </td>
                <td class="tdata8">
                  <a (click)="pdf_download(list.uuid)" class="btn-tb5">{{
                      'CONTRACTED_COMPANY_LIST.DISPLAY_PDF_BUTTON' | translate }}
                  </a>
                </td>
                <td class="tdata8"  *ngIf="list.contracts_flg == '1'">
                  <a (click)="pdf_download_s3(list.uuid)" class="btn-tb5">{{
                      'CONTRACTED_COMPANY_LIST.DISPLAY_PDF_BUTTON' | translate }}
                  </a>
                </td>
                <td class="tdata8"  *ngIf="list.contracts_flg == '0'">
                  <a class="btn-tb5-disable">{{
                      'CONTRACTED_COMPANY_LIST.DISPLAY_PDF_BUTTON' | translate }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="filteredItems.length >= 1">
          <label class="pages">{{'COMMON.PAGE' | translate}} {{ currentIndex }}/{{ pageNumber }}</label>
        </div>
        <div class="page-point" *ngIf="filteredItems.length >= 1">
          <span
            [ngClass]="{ disabled: currentIndex == 1 || pageNumber == 0 }"
            (click)="prevPage()"
            class="prev"
            >&laquo;
          </span>
          <span
            *ngFor="let page of pagesIndex"
            [ngClass]="{ tod: currentIndex == page }"
            class="dot"
            (click)="setPage(page)"
          ></span>
          <span
            [ngClass]="{ disabled: currentIndex == pageNumber || pageNumber == 0 }"
            (click)="nextPage()"
            class="next"
            >&raquo;
          </span>
        </div>
        <div class="text-center" style="margin-top: 16px;max-width: 1500px;">
          <div class="col-12">
            <div class="display">
              <a (click)="csv()" class="btn-search csv-font">
                {{'COMMON.CSV' | translate}}
              </a>
              <i class="fa fa-print"></i>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen]="true"><p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>></ngx-spinner>
