<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ANNOUNCEMENT.TITLE' | translate }}</h3>
  <div class="card-body">
    <p class="left-border header-text2">{{ 'DELETE_ANNOUNCEMENT.TITLE' | translate }}</p>
    <p>{{ 'DELETE_ANNOUNCEMENT.TITLE_TEXT_2' | translate }}</p>
  </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="invoice p-3 mb-3">
              <div class="row invoice-info">
                <div class="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 invoice-col">
                  <address>
                    2021/03/10
                  </address>
                </div>
                <div class="col-12 col-md-7 col-sm-7 col-lg-5 col-xl-5 invoice-col">
                  <address>
                    定期メンテナンスがあります
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <div class="text-center finish2">
    <p>{{ 'DELETE_ANNOUNCEMENT.CENTER_TEXT' | translate }}</p>
  </div>
  <!-- button -->
  <div class="text-center">
    <div class="two-btn">
      <div class="display">
        <a (click)="back()" class="btn-grey">{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
        </a>
      </div>
      <div class="display">
        <a (click)="finish()" class="btn-pink">{{ 'COMMON.COMMON_OKEY_BUTTON' | translate }}
        </a>
      </div>
    </div>
  </div>
  <!-- button end -->
</div>