<div class="main-padding">
  <h3 class="header-bottom header-text1"> {{ 'MY_ACCOUNT.MAIL_ADDRESS_DESC' | translate }}</h3>
  <div class="text-center">
    <div class="text-center finish">
      {{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TITLE_1' | translate }}<br>{{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TITLE_2' | translate }}
    </div>
    <div class="text-center finish2">
      {{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TEXT_1' | translate }}<br>{{ 'MY_ACCOUNT.SENT_VERIFICATION_MAIL_TEXT_2' | translate }}
    </div>
  </div>     
</div>