<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'CONTRACTED_COMPANY_LIST.TITLE' | translate }}
  </h3>
  <div class="card-body">
    <p class="left-border header-text2">
      {{ 'CONTRACTED_COMPANY_DETAIL.TITLE' | translate }}
    </p>
  </div>
  <section class="content">
    <!-- 1 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.START_DATE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Application_Date }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.END_DATE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 button-top">
              <!-- <a
                *ngIf="EndDate == ''"
                (click)="Cancel()"
                class="btn-cancel"
                >{{
                  'CONTRACTED_COMPANY_DETAIL.CONTRACT_CANCEL' | translate
                }}</a
              > -->
              <h3 class="card-title">{{ EndDate }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.BEFORE_OR_AFTER' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporate_Status == '1'">
                {{ 'DETAILS.BEFORE_DEST' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporate_Status == '2'">
                {{ 'DETAILS.AFTER_DEST' | translate }}
              </h3>
              <h3 class="card-title long-text" *ngIf="Corporate_Status == '3'">
                {{ 'DETAILS.OTHERS_DEST' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title long-text">{{ Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 style="margin-top: -25px" class="card-title long-text">
                {{ Company_Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 5 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.ADDRESS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Post_Code }}<br />{{ Address_1 }}<br />
                {{ Address_2 }}{{ Address_3 }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 6 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.TELEPHONE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ TelePhone }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 7 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.MAIL_ADDRESS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Mail }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 8 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.CHARGER_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name }}</h3>
              <h3 class="card-title" style="padding-left: 18px">{{ Name }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
              <h3 class="card-title display">{{ Last_Name_Kana }}</h3>
              <h3 class="card-title" style="padding-left: 18px">
                {{ Name_Kana }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 9 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.DEPARTMENT_POSITION' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">
                {{ Staff_Post }}&ensp;{{ Staff_Position }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 10 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.NUMBER_OF_USERS' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Users }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 11 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.USAGE_HISTORY' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="History_Flg == '1'">
                {{ 'CONTRACT.USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="History_Flg == '0'">
                {{ 'CONTRACT.DON_T_USE_USED_HISTORY_RADIO_BUTTON' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 12 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.USAGE_FORM' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="Corporation_Form == '1'">
                {{ 'CONTRACT.CONTRACTED_COMPANY_EMPLOYEE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '2'">
                {{ 'CONTRACT.HEALTH_INSURANCE' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="Corporation_Form == '3'">
                {{ 'CONTRACT.WElFARE' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 6 -->
    <div class="card-body" *ngIf="Corporation_Form != '3'">
    <div class="row">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">
          {{ 'CONTRACTED_COMPANY_DETAIL.INSURANCE_CARD_DEST' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
        <div class="col-12">
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
            <h3 class="card-title">{{ health_code }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.MEMBERSHIP_FEE_BURDEN' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title" *ngIf="paid_form == '3'">
                {{ 'CONTRACT.COMPANY_REGISTER_PERSONAL_BURDEN' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="paid_form == 2">
                {{ 'CONTRACT.COMPANY_REGISTER_CORPORATE_BURDEN_GO' | translate }}
              </h3>
              <h3 class="card-title" *ngIf="paid_form == '1'">
                {{ 'CONTRACT.COMPANY_REGISTER_CORPORATE_BURDEN' | translate }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 13 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.COMPANY_NUMBER' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Corporation_No }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- プロモーションコード -->
    <div class="card-body" *ngIf="Corporation_Form != '3'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.PROMO_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Promo_Code1 }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="Corporation_Form == '3'">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
            <h3 class="card-title">
              {{ 'CONTRACTED_COMPANY_DETAIL.PROMO_CODE' | translate }}①
            </h3>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <div class="col-12">
              <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <h3 class="card-title">{{ Promo_Code1 }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
            <h3 class="card-title">
              {{ 'CONTRACTED_COMPANY_DETAIL.PROMO_CODE' | translate }}②
            </h3>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <div class="col-12">
              <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <h3 class="card-title">{{ Promo_Code2 }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 14 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.REGISTRATION_COPY' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 button-top">
              <a class="btn-pdf pdf-text" (click)="pdf_download()">
                <i class="nav-icon pdf"></i
                >{{
                  'CONTRACTED_COMPANY_DETAIL.REGISTRATION_COPY_PDF' | translate
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'CONTRACTED_COMPANY_DETAIL.CONTRACT_PDF' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 button-top">
              <a class="btn-pdf pdf-text" (click)="pdf_download_s3()" *ngIf="ContractsFLG == 1">
                <i class="nav-icon pdf"></i
                >{{
                  'CONTRACTED_COMPANY_DETAIL.CONTRACT_PDF_BUTTON' | translate
                }}
              </a>
              <a class="btn-pdf btn-disable pdf-text" *ngIf="ContractsFLG == 0">
                <i class="nav-icon pdf"></i
                >{{
                  'CONTRACTED_COMPANY_DETAIL.CONTRACT_PDF_BUTTON' | translate
                }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 15 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_CODE' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Code }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 16 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.ACQUIRED_COMPANY_NAME' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Acquired_Company_Name }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 月会費 -->
    <div class="card-body" *ngIf="Corporation_Form != '3'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title">
            {{ 'DETAILS.MONTHLY_FEE_DEST' | translate }}
          </h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title">{{ Fees1 }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="Corporation_Form == '3'">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
            <h3 class="card-title">
              {{ 'DETAILS.MONTHLY_FEE_DEST' | translate }}①
            </h3>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <div class="col-12">
              <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <h3 class="card-title">{{ Fees1 }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
            <h3 class="card-title">
              {{ 'DETAILS.MONTHLY_FEE_DEST' | translate }}②
            </h3>
          </div>
          <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <div class="col-12">
              <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <h3 class="card-title">{{ Fees2 }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center" *ngIf="wf_contract_result_resend && wf_contract_list_detail">
      <div class="two-btn">
        <div class="display" *ngIf="EndDate == '' || EndDate == null">
          <a (click)="send()" class="btn-pink"
            >{{
              'CONTRACTED_COMPANY_DETAIL.EXAMINATION_RESULT_RESEND' | translate
            }}
          </a>
        </div>
      </div>
    </div>
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey"
            >{{ 'COMMON.COMMON_BACK_BUTTON' | translate }}
          </a>
        </div>
        <div class="display" *ngIf="EndDate == '' || EndDate == null && wf_contract_modify_edit && wf_contract_list_detail">
          <a (click)="editor()" class="btn-pink"
            >{{ 'CONTRACTED_COMPANY_DETAIL.MODIFY' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- button end -->
  </section>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>
