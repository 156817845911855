<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'MY_ACCOUNT.TITLE' | translate }}</h3>
  <div class="card-body">
    <p class="left-border header-text2">{{ 'MY_ACCOUNT.TITLE_TEXT_1' | translate }}</p>
    <p class="card-title">{{ 'MY_ACCOUNT.TITLE_REGISTER_TEXT' | translate }}</p>
  </div>
    <!-- Names -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'MY_ACCOUNT.NAME_DESC' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <span class="badge badge-info">{{ 'COMMON.COMMON_REQUIRE_LABEL_1' | translate }}</span>
            <div class="custom-control display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5"
            style="width: 250px;">
              <input  type="text"
                      class="form-control"
                      placeholder="{{ 'MY_ACCOUNT.SURNAME_PLACEHOLDER' | translate }}"
                      name="Last_Name"
                      [(ngModel)]="Last_Name"
                      >
            </div>
            <div class="display col-12 col-sm-5	col-md-5 col-lg-5 col-xl-5 names1"
            style="width: 250px;">
              <input  type="text"
                      class="form-control"
                      placeholder="{{ 'MY_ACCOUNT.NAME_PLACEHOLDER' | translate }}"
                      name="First_Name"
                      [(ngModel)]="First_Name">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Department_Name  -->
    <div class="card-body" *ngIf="Department_Name">
      <div class="row">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'MY_ACCOUNT.DEPARTMENT_DESC' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title m1"> {{Department_Name}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Shopname -->
    <div class="card-body" *ngIf="Shop_Name">
      <div class="row">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'MY_ACCOUNT.STORE_NAME_DESC' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title m1"> {{Shop_Name}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mail -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'MY_ACCOUNT.MAIL_ADDRESS_DESC' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
              <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <h3 class="card-title m1"> {{Mail_Address}}</h3>
              </div>
          </div>
          <div class="col-12" style="margin-top: 10px;" *ngIf="wf_myaccount_mail_edit">
            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" style="display: inline;">
              <a (click)="mailSended()" class="btn-mini"> {{ 'MY_ACCOUNT.MAIL_CHANGE_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Auth name -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4	col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'MY_ACCOUNT.AUTHORITY_DESC' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="col-12">
            <div class="col-12 col-sm-8	col-md-8 col-lg-8 col-xl-8">
              <h3 class="card-title m1">{{User_Auth_Name}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Button -->
    <div class="text-center">
      <!-- Error text -->
      <div class="text-center" style="height: 34px">
        <p class="text1" style="display: none;" id="error"></p>
        <p class="text2" style="display: none;" id="correct"></p>
      </div>
      <div class="two-btn">
        <div class="display">
          <a (click)="update()" class="btn-pink">
            {{ 'MY_ACCOUNT.REGISTER_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>