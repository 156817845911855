<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'UNPAID_LIST.TITLE' | translate }}</h3>  
  
  <!-- Section №1 -->
  <section class="content" id="confirm">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'UNPAID_USER_APPLY.TITLE' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body ">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'UNPAID_USER_APPLY.COMPANY_NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
            <h3 class="card-title display">{{ CompanyName }}</h3>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'UNPAID_USER_APPLY.EMPLOYEE_NUMBER_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{ UserNo }}</h3>
        </div>
      </div>
    </div>
    <!-- 3 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'UNPAID_USER_APPLY.NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{ UserName}}</h3>
        </div>
      </div>
    </div>
    <!-- 4 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'UNPAID_USER_APPLY.TOTAL_ACCOUNTS_PAYABLE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{ UnpaidAmount }}</h3>
        </div>
      </div>
    </div>
    <!-- 5-->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'UNPAID_USER_APPLY.WITHDRAWAL_DATE_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{ EndDate }}</h3>
        </div>
      </div>
    </div>
    <!-- buttons -->
    <div class="text-center about">
      <p>{{ 'UNPAID_USER_APPLY.CENTER_TEXT' | translate }}</p>
    </div>
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">{{ 'COMMON.COMMON_NO_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="next()" class="btn-pink">{{ 'COMMON.COMMON_OKEY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </section>
  <!-- End Section №2 -->
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>