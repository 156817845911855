import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AppService } from '../../utils/services/app.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public loginForm: FormGroup;
  public isAuthLoading = false;
  public email;
  public password;
  public pages;
  public logoUrl;

  constructor(
    private renderer: Renderer2,
    private appService: AppService,
    public translate: TranslateService,
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.loginForm = new FormGroup({
      email: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  onChange() {
    document.getElementById('errormsglogin').innerHTML = '';
  }

  login() {
    if (this.loginForm.valid) {
      this.spinner.show();
      this.appService.login(this.email, this.password).then(
        (res) => {
        if (res !== undefined) {
          this.data.uuid = res.attributes['sub'];
          this.data.loggedEmail = this.email;
          this.data.loggedPassword = this.password;
          localStorage.setItem(
            'token',
            res.signInUserSession.getIdToken().getJwtToken()
          );
          this.spinner.hide();
          this.router.navigate(['/']);
        } else {
          this.spinner.hide();
          this.translate.get('LOGIN.LOGIN_FAILED').subscribe(
            (res) => (document.getElementById('errormsglogin').innerHTML = res)
          );
        }
      });
    } else {
      this.spinner.hide();
      this.translate.get('LOGIN.LOGIN_FAILED').subscribe(
        (res) => (document.getElementById('errormsglogin').innerHTML = res)
      );
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
  }
}
