import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-edit-comp',
  templateUrl: './edit-comp.component.html',
  styleUrls: ['./edit-comp.component.scss'],
})
export class AnnEditCompComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService, public data: DataProvider
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-announcement';
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      retypePassword: new FormControl(null, Validators.required),
    });
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'change-password-page'
    );
  }
  notification() {
    this.router.navigate(['/wf-announcement']);
  }
}
