import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'src/app/utils/services/app.service';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss'],
})
export class MyAccountComponent implements OnInit {
  public Last_Name = '';
  public First_Name = '';
  public User_Auth = '';
  public Mail_Address = '';
  public Department_Name = '';
  public User_Auth_Name = '';
  public Shop_Name = '';
  public Management_Company_Code = '';
  public pages;
  public wf_myaccount_mail_edit = false;


  constructor(
    private router: Router,
    public data: DataProvider,
    private appService: AppService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService,
  ) { }


  ngOnInit() {
    this.data.menuPageTitle = 'myaccount';
    this.spinner.show();
    this.rest.Get_WFAccount(this.data.uuid).subscribe(
      (res) => {
        console_log(res);
        this.Last_Name = res.data.user_last_name;
        this.First_Name = res.data.user_first_name;
        this.Mail_Address = res.data.mail_address;
        this.Department_Name = res.data.department_name;
        this.Shop_Name = res.data.shop_name;
        this.User_Auth_Name = res.data.user_auth_type;
        this.Management_Company_Code = res.data.management_company_code;

        if (this.User_Auth_Name == 'システム管理者') {
          this.User_Auth = 'wf-sys-admin';
        } else if (this.User_Auth_Name == 'YGC本部') {
          this.User_Auth = 'wf-corp-staff';
        } else if (this.User_Auth_Name == '直営店スタッフ') {
          this.User_Auth = 'wf-direct-staff';
        } else if (this.User_Auth_Name == '加盟店本部') {
          this.User_Auth = 'wf-fc-head';
        } else if (this.User_Auth_Name == '加盟店スタッフ') {
          this.User_Auth = 'wf-fc-staff';
        } else if (this.User_Auth_Name == '加盟店本部(制限有)') {
          this.User_Auth = 'wf-fc-head-limit';
        }
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages = res.data;
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].pagename == 'wf-myaccount-mail-edit') {
            console_log('wf-myaccount-mail-edit');
            if (this.pages[i].auth == 'F') {
              this.wf_myaccount_mail_edit = true;
            }
            if (this.pages[i].auth == 'D') {
              this.wf_myaccount_mail_edit = false;
            }
          }
        }
      },
    );
  }
  dataValidation() {
    let err = false;
    let errTxt = '';

    if (this.First_Name == null || this.First_Name == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_FIRST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_LAST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      document.getElementById('correct').style.display = 'none';
      return false;
    }
    return true;
  }
  update() {
    this.spinner.show();
    if (this.dataValidation() == false) {
      this.spinner.hide();
      return true;
    } else {
      document.getElementById('error').style.display = 'none';
      const body = {
        data: {
          user_uuid: this.data.uuid,
          uuid: this.data.uuid,
          user_last_name: this.Last_Name,
          user_first_name: this.First_Name,
          user_auth: this.User_Auth,
          mail_address: this.Mail_Address,
          department_name: this.Department_Name,
          shop_name: this.Shop_Name,
          management_company_code: this.Management_Company_Code
        }
      };
      console_log(body);
      this.rest.Update_CognitoUser(
        this.data.uuid,
        this.data.uuid,
        this.Last_Name,
        this.First_Name,
        this.User_Auth,
        this.Mail_Address,
        this.Department_Name,
        this.Shop_Name,
        this.Management_Company_Code).subscribe(
          (res) => {
            console_log(res);
            this.translate.get('MY_ACCOUNT.TITLE_CHANGED_MY_ACCOUNT_TEXT').subscribe(
              (res) => {
                document.getElementById('correct').innerHTML = res
              }
            );
            document.getElementById('correct').style.display = 'block';
            this.rest.Get_WFAccount(this.data.uuid).subscribe(
              (res) => {
                console_log(res);
                this.data.firstName = res.data.user_first_name;
                this.data.lastName = res.data.user_last_name;
                this.spinner.hide();
              },
              (err) => {
                console_log(err);
                this.spinner.hide();
              }
            );
          },
          (err) => {
            console_log(err);
            document.getElementById('error').style.display = 'block';
            this.translate.get('ERROR.' + err.error.error.code).subscribe(
              (res) => {
                document.getElementById('error').innerHTML = res
              }
            );
            this.spinner.hide();
          }
        );
    }
  }
  mailSended() {
    this.router.navigate(['/wf-myaccount-mail-edit']);
  }
}
