import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-registrant-list',
  templateUrl: './registrant-list.component.html',
  styleUrls: ['./registrant-list.component.scss'],
})
export class RegistrantListComponent implements OnInit {
  public csv;
  public CompanyName = '';
  public AcquiredInfo;
  public acquired_data = '';
  public acquired_name = '';
  public FamilyName = '';
  public Number = '';
  public name = '';
  public user_no = '';
  public user_status: string = '';
  public customCheckbox1 = false;
  public customCheckbox2 = false;
  public customCheckbox3 = false;
  public customCheckbox4 = false;
  public User_Auth_Name;
  public User_Auth;
  public Management_Company_Name;
  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {
  }
  ngOnInit() {
    this.spinner.show();
    this.data.menuPageTitle = 'wf-registrant-list';
    this.widthChanger('main-header', 360);

    this.rest.Get_WFAccount(this.data.uuid).subscribe((res1) => {
      this.User_Auth_Name = res1.data.user_auth_type;

      if (this.User_Auth_Name == 'システム管理者') {
        this.User_Auth = 'wf-sys-admin';
      } else if (this.User_Auth_Name == 'YGC本部') {
        this.User_Auth = 'wf-corp-staff';
      } else if (this.User_Auth_Name == '直営店スタッフ') {
        this.User_Auth = 'wf-direct-staff';
      } else if (this.User_Auth_Name == '加盟店本部') {
        this.User_Auth = 'wf-fc-head';
      } else if (this.User_Auth_Name == '加盟店スタッフ') {
        this.User_Auth = 'wf-fc-staff';
      } else if (this.User_Auth_Name == '加盟店本部(制限有)') {
        this.User_Auth = 'wf-fc-head';
      }
      this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
        (res) => {
          console_log(res);
          this.AcquiredInfo = res.data.AcquiredInfo;
          if(this.User_Auth ==  'wf-fc-head'){
            this.acquired_name = res1.data.management_company_name;
          }

          // for(var i = 0; i< this.AcquiredInfo.length; i++){

          //   if(this.AcquiredInfo[i].acquired_name == this.acquired_name){
          //     this.acquired_data = this.AcquiredInfo[i].acquired_code + ':' + this.AcquiredInfo[i].acquired_name;
          //   }
          // }
          console_log(this.acquired_data);
          this.rest.Get_MembersAdmin(this.data.uuid,this.CompanyName,this.acquired_name,this.name, this.user_no, this.user_status).subscribe((res) => {
          
            this.filteredItems = res.data.PersonInfo;
            this.currentIndex = 1;
            this.pageStart = 1;
            this.pages = 6;
        
            this.pageNumber = parseInt(
              '' + this.filteredItems.length / this.pageSize
            );
            if (this.filteredItems.length % this.pageSize != 0) {
              this.pageNumber++;
            }
            if (this.pageNumber < this.pages) {
              this.pages = this.pageNumber;
            }
            this.refreshItems();
            this.spinner.hide();
  
          }, (err) => {
              console_log(err);
              this.spinner.hide();
          });
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
        }
      );
    }, (err) => {
      console_log(err);
      this.spinner.hide();
    });

    // this.filteredItems = [
    //   { corporation_no: "XXXX1", company_name: "試験法人", acquired_name: "ヤマウチ", business_name: "試験一郎", membership: "1000000001", number: "2159-230-01", usage_situation: "継続中", date1: "2023-01-01", date2: "", count: "20"},
    //   { corporation_no: "XXXX1", company_name: "試験法人", acquired_name: "ヤマウチ", business_name: "試験二郎", membership: "1000000002", number: "2159-231-01", usage_situation: "継続中", date1: "2023-01-01", date2: "", count: "30"},
    //   { corporation_no: "XXXX1", company_name: "試験法人", acquired_name: "ヤマウチ", business_name: "試験三郎", membership: "1000000003", number: "2159-232-01", usage_situation: "休会", date1: "2022-01-01", date2: "2022-05-01", count: "0"},
    //   { corporation_no: "XXXX2", company_name: "検証組合", acquired_name: "法人営業本部", business_name: "検証花子", membership: "1000000004", number: "8732-020-01", usage_situation: "退会", date1: "2022-06-01", date2: "", count: "0"},
    //   { corporation_no: "XXXX2", company_name: "検証組合", acquired_name: "法人営業本部", business_name: "検証太朗", membership: "1000000005", number: "532-3958-01", usage_situation: "継続中", date1: "2022-12-01", date2: "2022-12-01", count: "40"},
    //   { corporation_no: "XXXX3", company_name: "テストサービス", acquired_name: "ヤマアークランズウチ", business_name: "鈴木試験", membership: "1000000006", number: "1234567890", usage_situation: "継続中", date1: "2022-12-01", date2: "", count: "20"},
    // ];
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }

  Search() {
    this.spinner.show();
    if(this.customCheckbox1){
      var v1: string = '1';
    } else {
      var v1: string = ''
    }
    if(this.customCheckbox2){
      var v2: string = '2';
    } else {
      var v2: string = '';
    }
    if(this.customCheckbox3){
      var v3: string = '3';
    } else {
      var v3: string = '';
    }
    if(this.customCheckbox4){
      var v4: string = '4';
    } else {
      var v4: string = '';
    }

    this.user_status = v1 + v2 + v3 + v4;
    if(this.User_Auth !=  'wf-fc-head'){
      if( this.acquired_data  != ''){
        this.acquired_name = this.acquired_data.split(':')[1];
      } else {
        this.acquired_name = ''
      }
    }
    this.rest.Get_MembersAdmin(this.data.uuid,this.CompanyName,this.acquired_name,this.FamilyName, this.Number, this.user_status).subscribe((res) => {
      console_log(res);
      this.filteredItems = res.data.PersonInfo;
      this.currentIndex = 1;
      this.pageStart = 1;
      this.pages = 6;
  
      this.pageNumber = parseInt(
        '' + this.filteredItems.length / this.pageSize
      );
      if (this.filteredItems.length % this.pageSize != 0) {
        this.pageNumber++;
      }
      if (this.pageNumber < this.pages) {
        this.pages = this.pageNumber;
      }
      this.refreshItems();
      this.spinner.hide();

    }, (err) => {
        console_log(err);
        this.spinner.hide();
    });
  }

  csvOutput() {
    const body = {
      corporation_no : "法人番号",
      company_name : "会社名",
      acquired_name : "獲得企業名",
      name : "氏名",
      casio_id: "会員番号",
      user_no: "個人番号",
      use_status : "利用状況",
      app_start : "契約日",
      app_end : "退会日",
      use_count : "利用回数"
    }

    var csvItems = [];
    csvItems = this.filteredItems.map((_arrayElement) => Object.assign({}, _arrayElement)); 
    for(let i = 0; i < csvItems.length; i++){
      if(csvItems[i].use_status == '1'){
        csvItems[i].use_status = '未入会'
      }else if(csvItems[i].use_status == '2'){
        csvItems[i].use_status = '継続中'
      }else if(csvItems[i].use_status == '3'){
        csvItems[i].use_status = '休会'
      }else if(csvItems[i].use_status == '4'){
        csvItems[i].use_status = '退会'
      }
    }

    const replacer = (key, value) => value === null ? '' : value 
    const header = Object.keys(body);
    const headerValue = Object.values(body);
    this.csv= csvItems.map(row => header.map(fieldName =>
    JSON.stringify(row[fieldName], replacer)).join(','));
    this.csv.unshift(headerValue.join(','));
    this.csv = this.csv.join('\r\n');

    // Create link and download

    var download_name = '登録者一覧_' + this.datepipe.transform(new Date(), 'yyyyMMddHHmmss');
    var link = document.createElement('a');
    link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(this.csv));
    link.setAttribute('download', download_name + '.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  order(id){
    var apply_list:Array<List> = this.filteredItems;
    if(id == 1){
      apply_list.sort((a,b) =>  a.app_start < b.app_start ? 1 : a.app_start > b.app_start ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 2){
      apply_list.sort((a,b) => a.app_start < b.app_start ? -1 : a.app_start > b.app_start ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 3){
      apply_list.sort((a,b) => a.app_end < b.app_end ? 1 : a.app_end > b.app_end ? -1 : 0)	// descending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }else if(id == 4){
      apply_list.sort((a,b) => a.app_end < b.app_end ? -1 : a.app_end > b.app_end ? 1 : 0)	// Ascending
      this.items = apply_list.slice(
        (this.currentIndex - 1) * this.pageSize,
        this.currentIndex * this.pageSize
      );
      this.pagesIndex = this.fillArray();
    }
  }
}
class List{
  corporation_no: String
  company_name: String
  acquired_name: String
  business_name: String
  number: String
  usage_situation: String
  count: String
  app_start: String;
  app_end: String;
}
