import { Injectable } from '@angular/core';
import { Console } from 'console';
import { USER_POOL_ID, CLIENT_ID, environment } from '../../environments/environment';
@Injectable()
export class DataProvider {
  public sidebarMenuOpened: boolean = true;
  public menuPageTitle: string = 'home';
  public canLogin: boolean = false;
  public uuid: string = '';
  public management_company_code: string = '';
  public loggedUsername: string = '';
  public loggedEmail: string = '';
  public loggedPassword: string = '';
  public globalLoading: boolean = false;
  public poolData = {
    UserPoolId: USER_POOL_ID,
    ClientId: CLIENT_ID,
  };
  public menuHome: boolean = false;
  public menuHomePage: string = '';
  public menuCreate: boolean = false;
  public menuCreatePage: string = '';
  public menuAccountList: boolean = false;
  public menuAccountListPage: string = '';
  public menuAnnouncement: boolean = false;
  public menuAnnouncementPage: string = '';
  public menuApplyList: boolean = false;
  public menuApplyListPage: string = '';
  public menuContractList: boolean = false;
  public menuContractListPage: string = '';
  public menuRegistrantList: boolean = false;
  public menuRegistrantListPage: string = '';
  public menuPoint: boolean = false;
  public menuPointPage: string = '';
  public menuDistributionList: boolean = false;
  public menuDistributionListPage: string = '';
  public menuUnpaidList: boolean = false;
  public menuUnpaidListPage: string = '';
  public lastName: string = '';
  public firstName: string = '';
  public ClubCode: string = '';
  public ContractTypeCode: string = '';
  public memberID: string = '';
  public memberName: string = '';
  public Price: string = '';
  public Point: string = '';
  public AcquiredCode: string = '';
  public AcquiredName: string = '';
  public backButton: string = '';
  constructor() { }
}
export function console_log(res) {
  if (!environment.production) {
    console.log(res);
  }
}