<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ANNOUNCEMENT.TITLE' | translate }}</h3>
  <!-- Radio -->
  <div class="card-body" style="padding-left: 20px;">
  <div class="row" style="margin-top: 50px; margin-right: 0px; padding-left: 5px;">
    <div class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" checked id="r1" name="customRadio">
          <label for="r1" class="custom-control-label ftext">{{ 'COMMON.CASE_10' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" id="r2" name="customRadio">
          <label for="r2" class="custom-control-label ftext">{{ 'COMMON.CASE_20' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" id="r3" name="customRadio">
          <label for="r3" class="custom-control-label ftext">{{ 'COMMON.CASE_50' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2">
      <div class="form-group">
        <div class="custom-control custom-radio">
          <input class="custom-control-input custom-control-input-danger custom-control-input-outline" type="radio" id="r4" name="customRadio">
          <label for="r4" class="custom-control-label ftext">{{ 'COMMON.CASE_100' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- radio end -->
  <!-- Table section -->
  <section class="content"> 
    <div class="container-fluid" style="color: black;">
      <div class="row" style="width: 100%;overflow-x: auto;">
              <table id="example2" class="table table-bordered">
                <thead>
                  <tr class="text-center">
                    <th class="ftext">{{ 'ANNOUNCEMENT.DATE_COLUMN' | translate }}</th>
                    <th class="ftext">{{ 'ANNOUNCEMENT.CONTENTS_COLUMN' | translate }}</th>
                    <th class="ftext">{{ 'ANNOUNCEMENT.MODIFY_BUTTON' | translate }}</th>
                    <th class="ftext">{{ 'ANNOUNCEMENT.DELETE_BUTTON' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data1 of data1" class="text-center">
                    <td class="tbtext">{{ data1.Date }}</td>
                    <td class="tbtext"  style="text-align: left;">{{ data1.Contents }}</td>
                    <td><a (click)="edit()" class="btn-mini-tb">{{ 'ANNOUNCEMENT.MODIFY_BUTTON' | translate }}</a></td>
                    <td><a (click)="delete()" class="btn-mini-tb">{{ 'ANNOUNCEMENT.DELETE_BUTTON' | translate }}</a></td>
                  </tr>
                </tbody>
              </table>
              
            </div>
        </div>
  </section>
</div>
  <!-- Table section end -->

    <!-- Button -->
    <div class="text-center">
      <div class="col-12">
        <div class="display" style="margin-top: 41px;">
          <a (click)="add()" class="btn-log">
            {{ 'ANNOUNCEMENT.ADD_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
</div>