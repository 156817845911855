import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { console_log } from 'src/providers/data/data';

@Component({
  selector: 'app-create-account-comp',
  templateUrl: './create-account-comp.component.html',
  styleUrls: ['./create-account-comp.component.scss'],
})
export class CreatAccCompleteComponent implements OnInit, OnDestroy {
  public crypto_uuid = '';
  public crypto_pattern = '';
  public logoUrl;
  public error_code = '';
  public uuid = '';
  constructor(
    private renderer: Renderer2,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public rest: CallapiService,
  ) { }

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'login-page');
    this.crypto_uuid = this.route.snapshot.queryParams["id"];
    this.crypto_pattern = this.route.snapshot.queryParams["cpn"];
    console_log(this.crypto_uuid);
    console_log(this.crypto_pattern);
    localStorage.setItem('token',this.crypto_uuid);
    this.rest.Activate_CognitoUser(this.crypto_uuid, this.crypto_pattern).subscribe(
      (res) => {
        console_log(res);
        document.getElementById('error').style.display = 'none';
        document.getElementById('correct').style.display = 'block';
      },
      (err) => {
        console_log(err);
        this.error_code = err.error.error.code;
        document.getElementById('correct').style.display = 'none';
        document.getElementById('error').style.display = 'block';
        if(this.error_code != null){
          this.translate.get('ETITLE.' + this.error_code).subscribe((res) => (document.getElementById('err_title1').innerHTML = res));
          this.translate.get('ERROR.' + this.error_code).subscribe(
            (res) => {
              var UUID = /uuid/gi; 
              var error_str = res.replace(UUID, this.uuid);
              document.getElementById('err_text1').innerHTML = error_str + '<br>' + '('+this.error_code+')'
            }
          );
        }
      }
    )
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'login-page'
    );
  }
}
