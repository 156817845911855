import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-mail-error',
  templateUrl: './mail-error.component.html',
  styleUrls: ['./mail-error.component.scss'],
})
export class MyAccountMailErrComponent implements OnInit, OnDestroy {
  public error_code;
  public logoUrl

  constructor(
    private renderer: Renderer2,
    public data: DataProvider,
    public router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'change-password-page');
    this.spinner.show();
    this.error_code = this.route.snapshot.paramMap.get('code');
    if(!this.error_code){
      this.spinner.hide();
      document.getElementById('err_title').style.display = 'block';
    } else {
      this.spinner.hide();
      this.translate.get('ETITLE.' + this.error_code).subscribe((res) => (document.getElementById('err_title').innerHTML = res));
      document.getElementById('err_title').style.display = 'block';
      this.translate.get('ERROR.' + this.error_code).subscribe((res) => (document.getElementById('err_text').innerHTML = res + '<br>' + '('+this.error_code+')'));
      document.getElementById('err_text').style.display = 'block';
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'mail-err'
    );
  }
}
