import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-apply-detail',
  templateUrl: './apply-detail.component.html',
  styleUrls: ['./apply-detail.component.scss'],
})
export class ApplyDetailComponent implements OnInit {
  
  public documentpdf;
  public uuid;
  public Corporation_Form;
  public Company_Name;
  public Company_Name_Kana;
  public Post_Code;
  public Address_1;
  public Address_2;
  public Address_3;
  public TelePhone;
  public Last_Name;
  public Name;
  public Last_Name_Kana;
  public Name_Kana;
  public Staff_Post;
  public Staff_Position;
  public History_Flg;
  public Corporate_Status;
  public Corporation_No;
  public Acquired_Company_Code;
  public Acquired_Company_Name;
  public Application_Date;
  public Application_Time;
  public Judge_Status;
  public Fees1;
  public Fees2;
  public Note;
  public Judge_Ng_Cause;
  public Judge_Disp_Cause;
  public pages1;
  public wf_contract_apply_edit: boolean;
  public wf_contract_apply_modify: boolean;
  public wf_contract_apply_detail: boolean;
  public paid_form;
  public health_code;
  
  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-apply-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show()
    this.rest.Get_ApplicationsId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.History_Flg = res.data.history_flg;
        this.Corporation_No = res.data.corporation_no;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.Acquired_Company_Name = res.data.acquired_name;
        this.Application_Date = this.datepipe.transform(res.data.app_date, 'yyyy/MM/dd');
        this.Application_Time = res.data.app_time;
        this.Judge_Status = res.data.judge_state;
        this.Fees1 = res.data.fees1;
        this.Fees2 = res.data.fees2;
        this.Note = res.data.note;
        this.Judge_Ng_Cause = res.data.judge_ng_cause;
        this.Judge_Disp_Cause = res.data.judge_disp_cause;
        this.health_code = res.data.health_code;
        this.paid_form = res.data.paid_form;
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages1 = res.data;
        for (let i = 0; i < this.pages1.length; i++) {
          if (this.pages1[i].pagename == 'wf-contract-apply-detail') {
            console_log('wf-contract-apply-detail');
            if (this.pages1[i].auth == 'F') {
              this.wf_contract_apply_detail = true;
            }
            if (this.pages1[i].auth == 'R') {
              this.wf_contract_apply_detail = false;
            }
          }
          if (this.pages1[i].pagename == 'wf-contract-apply-edit') {
            console_log('wf-contract-apply-edit');
            if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
              this.wf_contract_apply_edit = true;
            }
            if (this.pages1[i].auth == 'D') {
              this.wf_contract_apply_edit = false;
            }
          }
          if (this.pages1[i].pagename == 'wf-contract-apply-modify') {
            console_log('wf-contract-apply-modify');
            if (this.pages1[i].auth == 'F' || this.pages1[i].auth == 'R') {
              this.wf_contract_apply_modify = true;
            }
            if (this.pages1[i].auth == 'D') {
              this.wf_contract_apply_modify = false;
            }
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.widthChanger('main-header', 752);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          
          header.style.minWidth = width.toString() + 'px';
      }
  }
  back() {
    this.router.navigate(['/wf-contract-apply-list']);
  }
  ExaminationChange() {
    this.router.navigate(['/wf-contract-apply-edit', { uuid: this.uuid }]);
  }
  ContractChange() {
    this.router.navigate(['/wf-contract-apply-modify', { uuid: this.uuid }]);
  }
  pdf_download(){
    this.rest
      .Download_Regcertificates(this.data.uuid, this.uuid)
      .subscribe((res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      });
  }
}
