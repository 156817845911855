import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class AccountListDetailComponent implements OnInit {
  public uuid = '';
  public Last_Name = '';
  public First_Name = '';
  public Department_Shop = '';
  public Mail_Address = '';
  public User_Auth_Name = '';
  public pages;
  public wf_account_list_delete = false;
  public wf_account_list_detail = false;
  public wf_account_list_modify = false;
  public User_Auth;
  public AcquiredData;
  public StoreName;
  public User_Status = '';
  public limit;

  constructor(
    private router: Router,
    public data: DataProvider,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-account-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show();
    this.rest.Get_WFAccount(this.uuid).subscribe(
      (res) => {
        this.Last_Name = res.data.user_last_name;
        this.First_Name = res.data.user_first_name;
        this.Department_Shop = res.data.department_name;
        this.AcquiredData = res.data.management_company_code + ':' + res.data.management_company_name;
        this.Mail_Address = res.data.mail_address;
        this.StoreName = res.data.shop_name
        this.User_Auth_Name = res.data.user_auth_type;
        this.User_Status = res.data.user_status;
        if(this.User_Auth_Name == 'システム管理者'){
          this.User_Auth = 'wf-sys-admin';
        }else if(this.User_Auth_Name == 'YGC本部'){
          this.User_Auth = 'wf-corp-staff';
        }else if(this.User_Auth_Name == '直営店スタッフ'){
          this.User_Auth = 'wf-direct-staff';
        }else if(this.User_Auth_Name == '加盟店本部'){
          this.User_Auth = 'wf-fc-head';
          this.limit = 1;
        }else if(this.User_Auth_Name == '加盟店スタッフ'){
          this.User_Auth = 'wf-fc-staff';
        }else if(this.User_Auth_Name == '加盟店本部(制限有)'){
          this.User_Auth = 'wf-fc-head';
          this.limit = 0;
        }
        console_log(res);
      },
      (err) => {
        console_log(err);
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages = res.data;
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].pagename == 'wf-account-list-detail') {
            console_log('wf-account-list-detail');
            if (this.pages[i].auth == 'F') {
              this.wf_account_list_detail = true;
            }
            if (this.pages[i].auth == 'R') {
              this.wf_account_list_detail = false;
            }
          }
          if (this.pages[i].pagename == 'wf-account-list-modify') {
            console_log('wf-account-list-modify');
            if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
              this.wf_account_list_modify = true;
            }
            if (this.pages[i].auth == 'D') {
              this.wf_account_list_modify = false;
            }
          }
          if (this.pages[i].pagename == 'wf-account-list-delete') {
            console_log('wf-account-list-delete');
            if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
              this.wf_account_list_delete = true;
            }
            if (this.pages[i].auth == 'D') {
              this.wf_account_list_delete = false;
            }
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
  }
  back() {
    this.router.navigate(['/wf-account-list']);
  }
  next() {
    this.router.navigate(['/wf-account-list-modify', { uuid: this.uuid },]);
  }
  deleteAccount() {
    this.router.navigate(['/wf-account-list-delete', { uuid: this.uuid },]);
  }
  resend() {
    this.router.navigate(['/wf-account-list-resend', { uuid: this.uuid },]);
  }
}
