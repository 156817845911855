<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ACCOUNT_LIST.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="finish text-center">
      {{ 'ACCOUNT_DETAIL_FINISH.TEXT_1' | translate }}<br>{{ 'ACCOUNT_DETAIL_FINISH.TEXT_2' | translate }}
    </div>
    <div class="text-center">
      <div class="col-12">
        <div class="display finish2">
          <a (click)="back()" class="btn-grey">
            {{ 'ACCOUNT_DETAIL_FINISH.BACK_TO_ACCOUNT_LIST_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>