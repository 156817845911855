<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'POINT.TITLE' | translate }}
  </h3>
  <div class="card-body">
     <!-- Radio -->
     <div class="row select-radio-top">
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              checked
              id="r1"
              name="customRadio" (click)="selectedRadios(1)"
            />
            <label for="r1" class="custom-control-label ftext">{{
              'POINT.RADIO_BUTTON_1' | translate
            }}</label>
          </div>
        </div>
      </div>
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-radio">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="radio"
              id="r2"
              name="customRadio" (click)="selectedRadios(2)"
            />
            <label for="r2" class="custom-control-label ftext">{{
              'POINT.RADIO_BUTTON_2' | translate
            }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="Description == 1">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">
          {{ 'POINT.MEMBERSHIP_PLACEHOLDER' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="input-group" style="margin-left: 15px; width: 194px">
            <input
              type="text"
              class="form-control"
              id="memberID"
              [(ngModel)]="memberID"
              placeholder="{{ 'POINT.MEMBERSHIP_PLACEHOLDER' | translate }}"
              maxlength="10"
              onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
            />
            <img class="icon" src="assets/icons/sent.svg" />
          </div>
      </div>
    </div>
    <div class="row" *ngIf="Description == 2">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">
          {{ 'POINT.FULL_NAME' | translate }}
        </h3>
      </div>
      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="input-group" style="margin-left: 15px; width: 159px">
            <input
              type="text"
              class="form-control"
              id="lastNameKanji"
              [(ngModel)]="lastNameKanji"
              placeholder="{{'POINT.SURNAME_PLACEHOLDER' | translate}}"
            />
            <img class="icon" src="assets/icons/user-icon.svg" />
          </div>
          <div class="input-group" style="margin-left: 10px; width: 159px">
            <input
              type="text"
              class="form-control"
              id="firstNameKanji"
              [(ngModel)]="firstNameKanji"
              placeholder="{{'POINT.NAME_PLACEHOLDER' | translate}}"
            />
            <img class="icon" src="assets/icons/user-icon.svg" />
          </div>
      </div>
    </div>
    <div class="row" *ngIf="Description == 2">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title"></h3>
      </div>
      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <div class="input-group" style="margin-left: 15px; width: 159px">
            <input
              type="text"
              class="form-control"
              id="lastNameKana"
              [(ngModel)]="lastNameKana"
              placeholder="{{
                'POINT.SURNAME_KANA_PLACEHOLDER' | translate
              }}"
            />
            <img class="icon" src="assets/icons/user-icon.svg" />
          </div>
          <div class="input-group" style="margin-left: 10px; width: 159px">
            <input
              type="text"
              class="form-control"
              id="firstNameKana"
              [(ngModel)]="firstNameKana"
              placeholder="{{'POINT.NAME_KANA_PLACEHOLDER' | translate}}"
            />
            <img class="icon" src="assets/icons/user-icon.svg" />
          </div>
      </div>
    </div>
    <div class="row" *ngIf="Description == 2" style="padding-top: 20px;">
      <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
        <h3 class="card-title">{{'POINT.BIRTHDAY' | translate}}</h3>
      </div>
      <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
        <div class="input-group" style="margin-left: 15px; width: 159px">
          <input
            type="text"
            class="form-control"
            id="Birthday"
            [(ngModel)]="Birthday"
            maxlength="8"
            placeholder="{{'POINT.BIRTHDAY' | translate}}"
            autocomplete="off"
            onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
          />
          <img class="icon" src="assets/icons/date.svg" />
        </div>
        <div class="display">
          <h3 class="font">{{ 'POINT.PLEASE_ENTER_IN_8_DIGITS' | translate }}</h3>
        </div>
      </div>
    </div>
     <!-- Button -->
     <div class="text-center">
      <!-- Error text -->
      <div class="text-center">
        <p class="text1" style="display: none;" id="error"></p>
      </div>
      <div class="search-button">
        <div class="display">
          <a (click)="search()" class="btn-search">
            {{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
            <img
              class="search-icon"
              src="assets/icons/srch.svg"
            />
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>