<div class="main-padding">
  <h3 class="header-bottom header-text1">
    {{ 'DISTRIBUTION.TITLE' | translate }}
  </h3>
  <div class="card-body search-form-top">
    <!-- Input -->
    <div class="row">
      <div class="input-group company-name" *ngIf="management_company_code == 'HC01'">
        <input list="brow" class="form-control" [(ngModel)]="acquired_data" placeholder="{{'CONTRACT_APP_LIST.ACQUIRED_COMPANY_NAME_PLACEHOLDER' | translate}}">
        <datalist id="brow" required name="acquired_data">
          <option class="acquired-font" *ngFor="let i of AcquiredInfo" value="{{i.acquired_code}}:{{ i.acquired_name }}">
          </option>
        </datalist>
        <img id="book" class="icon" src="assets/icons/openbookblack.svg"/>
      </div>
      <h1 class="company-font" *ngIf="management_company_code != 'HC01'">{{management_company_name}}</h1>
      <div class="input-group datetime-cls">
      <dp-date-picker style="height: 40px;" [(ngModel)]="AppDate_1" [config]="config" mode="month" (onGoToCurrent)="GoToCurrent(Today)"></dp-date-picker>
        <img id='dicon' class="icon date-icon" src="assets/icons/date.svg"/>
      </div>
      <div class="search-button">
        <a target="_blank" class="btn-search" (click)="Search()"
          >{{ 'CONTRACT_APP_LIST.SEARCH_BUTTON' | translate }}
          <img
            class="search-icon"
            src="assets/icons/srch.svg"
          />
        </a>
      </div>
    </div>
    <!-- Check -->
    <div class="row checkbox-top">
      <!-- Check 1 -->
      <div class="column-check">
        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input
              class="custom-control-input custom-control-input-danger custom-control-input-outline"
              type="checkbox"
              id="customCheckbox1"
              [(ngModel)]="customCheckbox1"
              (change)="Breakdown(customCheckbox1)"
            />
            <label for="customCheckbox1" class="custom-control-label ftext">
              {{'DISTRIBUTION.BREAKDOWN_DISPLAY' | translate}}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Section -->
    <section class="content">
      <div class="container-fluid">
        <div class="row table-width">
          <table class="table table-bordered" style="min-width: auto;">
            <thead>
                <tr>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_1_ACQUIRED_COMPANY_NAME' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_2_STORE_NAME' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1" *ngIf="management_company_code == 'HC01'">
                    {{'DISTRIBUTION.TH_3_DISTRIBUTION_HEADQUARTERS' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_4_EARNINGS' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1" >
                    {{'DISTRIBUTION.TH_5_NUMBER_OF_PEOPLE_WON' | translate}}
                  </th>
                  <th class="ftext" rowspan="1" colspan="3" *ngIf="customCheckbox1">
                    {{'DISTRIBUTION.TH_6_BREAKDOWN_OF_THE_NUMBER_OF_PEOPLE_WON' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_7_USAGE_FEE' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_8_NUMBER_OF_USERS' | translate}}
                  </th>
                  <th class="ftext" rowspan="1" colspan="3" *ngIf="customCheckbox1">
                    {{'DISTRIBUTION.TH_9_BREAKDOWN_OF_THE_NUMBER_OF_USERS' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_10_BEFORE_AMOUNT' | translate}}
                  </th>
                  <th class="ftext th2" rowspan="2" colspan="1">
                    {{'DISTRIBUTION.TH_11_TOTAL' | translate}}
                  </th>
                </tr>
                <tr *ngIf="customCheckbox1">
                  <th class="ftext th3">{{'DISTRIBUTION.TH_CORPORATION_A' | translate}}</th>
                  <th class="ftext th3">{{'DISTRIBUTION.TH_CORPORATION_B' | translate}}</th>
                  <th class="ftext th3">{{'DISTRIBUTION.TH_CORPORATION_C' | translate}}</th>
                  <th class="ftext th3">{{'DISTRIBUTION.TH_CORPORATION_A' | translate}}</th>
                  <th class="ftext th3">{{'DISTRIBUTION.TH_CORPORATION_B' | translate}}</th>
                  <th class="ftext th3">{{'DISTRIBUTION.TH_CORPORATION_C' | translate}}</th>
                </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let data of items" [ngClass]="data.color_flg == 0 ? 'white' : data.color_flg == 1 ? 'pink' : data.color_flg == 2 ? 'orange' : ''">
                <td class="tbtext td1">{{data.acquired_name}}</td>
                <td class="tbtext td1">{{data.shop_name}}</td>
                <td class="tbtext td2" *ngIf="management_company_code == 'HC01'">{{parse(data.head_amount)}}</td>
                <td class="tbtext td2">{{parse(data.acquired_amount)}}</td>
                <td class="tbtext td2">{{parse(data.acquired_member_total)}}</td>
                <td class="tbtext td2" *ngIf="customCheckbox1">{{parse(data.acquired_member_A)}}</td>
                <td class="tbtext td2" *ngIf="customCheckbox1">{{parse(data.acquired_member_B)}}</td>
                <td class="tbtext td2" *ngIf="customCheckbox1">{{parse(data.acquired_member_C)}}</td>
                <td class="tbtext td2">{{parse(data.shop_amount)}}</td>
                <td class="tbtext td2">{{parse(data.shop_member_total)}}</td>
                <td class="tbtext td2" *ngIf="customCheckbox1">{{parse(data.shop_member_A)}}</td>
                <td class="tbtext td2" *ngIf="customCheckbox1">{{parse(data.shop_member_B)}}</td>
                <td class="tbtext td2" *ngIf="customCheckbox1">{{parse(data.shop_member_C)}}</td>
                <td class="tbtext td2">{{parse1(data.before_amount)}}</td>
                <td class="tbtext td2">{{parse1(data.total_amount)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- csv button -->
        <div class="csv-width">
          <div class="btn-cont">
            <a (click)="csv()" >
              {{'COMMON.CSV' | translate}}
            </a>
            <i class="fa fa-print"></i>
          </div>
        </div>
        <!-- csv button end -->
      </div>
    </section>
  </div>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>