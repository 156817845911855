import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProvider } from 'src/providers/data/data';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
})
export class AnnDeleteComponent implements OnInit {
  constructor(private router: Router, public data: DataProvider) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-announcement';
  }

  back() {
    this.router.navigate(['/wf-announcement']);
  }
  finish() {
    this.router.navigate(['/wf-announcement-delete-comp']);
  }
}
