<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ACCOUNT_LIST.TITLE' | translate }}</h3>
  <div class="content" id="detail">
    <div class="card-body">
      <p class="left-border header-text2">{{ 'ACCOUNT_RESEND.TITLE_1' | translate }}</p>
      <p class="card-title">{{ 'ACCOUNT_RESEND.TITLE_2' | translate }}</p>
    </div>
    <!-- 1 -->
    <div class="card-body ">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title display">{{ Last_Name }}&emsp;{{ First_Name }}</h3>
        </div>
      </div>
    </div>
    <!-- 2 -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.AUTHORITY_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{User_Auth_Name}}</h3>
        </div>
      </div>
    </div>
    <!-- DepartmentName-->
    <div class="card-body"  *ngIf="User_Auth == 'wf-sys-admin' || User_Auth == 'wf-corp-staff' || User_Auth == '' || User_Auth == 'wf-fc-head'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.DEPARTMENT_NAME' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{Department_Shop}}</h3>
        </div>
      </div>
    </div>
    <!-- ManagementCompany-->
    <div class="card-body" *ngIf="User_Auth == 'wf-fc-head' || User_Auth == 'wf-fc-staff'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.MANAGEMENT_COMPANY' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{AcquiredData}}</h3>
        </div>
      </div>
    </div>
    <!-- StoreName-->
    <div class="card-body"*ngIf="User_Auth == 'wf-direct-staff' || User_Auth == 'wf-fc-staff'">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.STORE_NAME_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{StoreName}}</h3>
        </div>
      </div>
    </div>
    <!-- E-Mail -->
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
          <h3 class="card-title"> {{ 'ACCOUNT_MANAGEMENT.MAIL_ADDRESS_DEST' | translate }}</h3>
        </div>
        <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-meaning">
          <h3 class="card-title"> {{Mail_Address}}</h3>
        </div>
      </div>
    </div>
    <!-- Text -->
    <div class="text-center finish2">
      <p>{{ 'ACCOUNT_RESEND.CENTER_TEXT_1' | translate }}</p>
    </div>
    <!-- Button -->
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="back()" class="btn-grey">{{ 'COMMON.COMMON_NO_BUTTON' | translate }}
          </a>
        </div>
        <div class="display">
          <a (click)="resend()" class="btn-pink">{{ 'COMMON.COMMON_OKEY_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
    <!-- Button end -->
  </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen]="true"><p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>></ngx-spinner>