import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-unpaid-user-list',
  templateUrl: './unpaid-user-list.component.html',
  styleUrls: ['./unpaid-user-list.component.scss'],
})
export class UnpaidUserListComponent implements OnInit {
  public AcquiredCompanyName = '';
  public Telephone = '';
  public Name = '';
  public DepartmentName = ''
  public Apply_Column: boolean = false;

  public customCheckbox1: boolean = true;
  public customCheckbox2: boolean = false;
  public ClosedDate;
  public today

  public Corporation;
  filteredItems = [];
  pages: number;
  pageSize: number = 10;
  pageNumber: number = 0;
  currentIndex: number = 1;
  items = [];
  pagesIndex: Array<number>;
  pageStart: number = 1;
  public pages1;
  constructor(
    private router: Router,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.data.menuPageTitle = 'wf-unpaid-list';
    this.widthChanger('main-header', 900);
    this.spinner.show();
    this.today = new Date();
    this.ClosedDate = this.datepipe.transform(this.today, 'yyyy/MM/dd');
    this.Corporation = this.route.snapshot.paramMap.get('corporation_no');
    this.rest.Get_UnpaidUser(this.data.uuid, this.Corporation).subscribe(
      (res) => {
        console_log(res);
        this.rest.Get_WFAccount(this.data.uuid).subscribe(
          (res) => {
            console_log(res);
            if (res.data.management_company_code == 'HC01') {
              this.Apply_Column = true;
            }
          },
          (err) => {
            console_log(err);
          }
        );
        this.AcquiredCompanyName = res.company_name;
        this.DepartmentName = res.staff_post_and_position;
        this.Name = res.staff_name_plus;
        this.Telephone = res.tel;
        this.filteredItems = []
        if (this.customCheckbox1 && !this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('未払'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else if (!this.customCheckbox1 && this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('支払済'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else {
          this.filteredItems = res.data.unpaid_list;
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
  fillArray(): any {
    var obj = new Array();
    for (
      var index = this.pageStart;
      index < this.pageStart + this.pages;
      index++
    ) {
      obj.push(index);
    }
    return obj;
  }

  refreshItems() {
    this.items = this.filteredItems.slice(
      (this.currentIndex - 1) * this.pageSize,
      this.currentIndex * this.pageSize
    );
    this.pagesIndex = this.fillArray();
  }

  prevPage() {
    if (this.currentIndex > 1) {
      this.currentIndex--;
    }
    if (this.currentIndex < this.pageStart) {
      this.pageStart = this.currentIndex;
    }
    this.refreshItems();
  }

  nextPage() {
    if (this.currentIndex < this.pageNumber) {
      this.currentIndex++;
    }
    if (this.currentIndex >= this.pageStart + this.pages) {
      this.pageStart = this.currentIndex - this.pages + 1;
    }
    this.refreshItems();
  }

  setPage(index: number) {
    this.currentIndex = index;
    this.refreshItems();
  }

  selectedRadios(id) {
    this.pageSize = id;
    this.currentIndex = 1;
    this.pageStart = 1;
    this.pages = 6;

    this.pageNumber = parseInt('' + this.filteredItems.length / this.pageSize);
    if (this.filteredItems.length % this.pageSize != 0) {
      this.pageNumber++;
    }

    if (this.pageNumber < this.pages) {
      this.pages = this.pageNumber;
    }
    this.refreshItems();
  }

  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i] as HTMLElement;
      header.style.minWidth = width.toString() + 'px';
    }
  }

  Apply(casio_id, user_no, user_name, unpaid_amount, end_date) {
    this.router.navigate(['/wf-unpaid-user-apply-conf', {
        casio_id: casio_id,
        corporation_no: this.Corporation,
        company_name: this.AcquiredCompanyName,
        user_no: user_no,
        user_name: user_name,
        unpaid_amount: unpaid_amount,
        end_date: end_date,
      }]
    );
  }
  back() {
    this.router.navigate(['/wf-unpaid-corp-list']);
  }
  FilterUnpaidStatus() {
    this.spinner.show();
    this.rest.Get_UnpaidUser(this.data.uuid, this.Corporation).subscribe(
      (res) => {
        this.filteredItems = [];
        if (this.customCheckbox1 && !this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('未払'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else if (!this.customCheckbox1 && this.customCheckbox2) {
          res.data.unpaid_list.forEach(element => {
            if (element.unpaid_status.toUpperCase().indexOf('支払済'.toUpperCase()) >= 0) {
              this.filteredItems.push(element);
            }
          });
        } else {
          this.filteredItems = res.data.unpaid_list;
        }
        this.currentIndex = 1;
        this.pageStart = 1;
        this.pages = 6;

        this.pageNumber = parseInt(
          '' + this.filteredItems.length / this.pageSize
        );
        if (this.filteredItems.length % this.pageSize != 0) {
          this.pageNumber++;
        }
        if (this.pageNumber < this.pages) {
          this.pages = this.pageNumber;
        }
        this.refreshItems();
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
  }
}