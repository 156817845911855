import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-modify',
  templateUrl: './modify.component.html',
  styleUrls: ['./modify.component.scss'],
})
export class AccountListModifyComponent implements OnInit {
  public uuid = '';
  public Name = '';
  public User_Auth_Name = '';
  
  public Last_Name = '';
  public First_Name = '';
  public User_Auth = '';
  public Mail_Address = '';
  public Management_Company_Code = '';
  public Department_Shop = '';
  public StoreName = '';

  public AcquiredData = '';
  public AcquiredInfo = [];
  public ShopData = [];
  public UserAuthData = [];
  public limit = 1;
  constructor(
    private router: Router, 
    public data: DataProvider,
    public rest: CallapiService,
    public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-account-list';
    this.widthChanger('main-header', 942);
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show();
    this.rest.Get_WFAccount(this.uuid).subscribe(
      (res) => {
        this.Last_Name = res.data.user_last_name;
        this.First_Name = res.data.user_first_name;
        this.Department_Shop = res.data.department_name;
        this.Management_Company_Code = res.data.management_company_code;
        this.AcquiredData = res.data.management_company_code + ':' + res.data.management_company_name;
        this.Mail_Address = res.data.mail_address.toString().trimLeft();
        this.StoreName = res.data.shop_name
        this.User_Auth_Name = res.data.user_auth_type;
        if(this.User_Auth_Name == 'システム管理者'){
          this.User_Auth = 'wf-sys-admin';
        }else if(this.User_Auth_Name == 'YGC本部'){
          this.User_Auth = 'wf-corp-staff';
        }else if(this.User_Auth_Name == '直営店スタッフ'){
          this.User_Auth = 'wf-direct-staff';
        }else if(this.User_Auth_Name == '加盟店本部'){
          this.User_Auth = 'wf-fc-head';
        }else if(this.User_Auth_Name == '加盟店スタッフ'){
          this.User_Auth = 'wf-fc-staff';
        }else if(this.User_Auth_Name == '加盟店本部(制限有)'){
          this.User_Auth = 'wf-fc-head';
          this.User_Auth_Name = '加盟店本部';
          this.limit = 0;
        }
        console_log(res);
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', { code: err.error.error.code }]);
      }
    );
    this.rest.Get_Authtypes(this.data.uuid, 'W').subscribe(
      (res) => {
        console_log(res);
        this.UserAuthData = res.data;
      },
      (err) => {
        console_log(err);
      }
    );
    this.rest.Get_AcquiredMaster(this.data.uuid).subscribe(
      (res) => {
        this.AcquiredInfo = res.data.AcquiredInfo;
      },
      (err) => {
        console_log(err);
      }
    );
    this.rest.Get_ShopNames(this.data.uuid, this.Management_Company_Code).subscribe(
      (res) => {
        console_log(res);
        this.ShopData = res.data.shopnames;
      },
      (err) => {
        console_log(err);
      }
    );
  }
  shopNames(event){
    var acquired_data = event.split(":");
    this.Management_Company_Code = acquired_data[0];
     this.rest.Get_ShopNames(this.data.uuid, this.Management_Company_Code).subscribe(
      (res) => {
        this.spinner.hide();
        console_log(res);
        this.ShopData = res.data.shopnames;
        if(res.data.shopnames == ''){
          this.StoreName = '';
        }
      },
      (err) => {
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        console_log(err);
      }
    );
  }
  userAuth(User_Auth){
    if(User_Auth == 'wf-sys-admin'){
      this.User_Auth_Name = 'システム管理者';
      this.Management_Company_Code = 'HC01';
      this.StoreName = '';
    }else if(User_Auth == 'wf-corp-staff'){
      this.User_Auth_Name = 'YGC本部';
      this.StoreName = '';
      this.Management_Company_Code = 'HC01'
    }else if(User_Auth == 'wf-direct-staff'){
      this.User_Auth_Name = '直営店スタッフ';
      this.Management_Company_Code = 'HC01'
    }else if(User_Auth == 'wf-fc-head' || User_Auth ==  'wf-fc-head-limit'){
      this.User_Auth_Name = '加盟店本部';
      this.StoreName = '';
    }else if(User_Auth == 'wf-fc-staff'){
      this.User_Auth_Name = '加盟店スタッフ';
    }
  }
  dataValidation() {
    let err = false;
    let errTxt = '';
    
    if (!this.validateEmail(this.Mail_Address)) {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_MAIL')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Mail_Address == null || this.Mail_Address == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_MAIL')
        .subscribe((res) => (errTxt = res));
    }
    if(this.User_Auth == 'wf-direct-staff' || this.User_Auth == 'wf-fc-staff'){
      if(this.StoreName == '' || this.StoreName == null){
        err = true;
        this.translate
          .get('ACCOUNT_CREATE_VALIDATION.ERROR_SHOP_NAME')
          .subscribe((res) => (errTxt = res));
      }
    }
    if(this.User_Auth == 'wf-fc-head' || this.User_Auth == 'wf-fc-staff' || this.User_Auth ==  'wf-fc-head-limit'){
      if (this.AcquiredData == '' || this.AcquiredData == null) {
        err = true;
        this.translate
          .get('ACCOUNT_CREATE_VALIDATION.ERROR_MANAGEMENT_COMPANY')
          .subscribe((res) => (errTxt = res));
      }
    }else{
      this.AcquiredData = '';
    }
    if(this.User_Auth == 'wf-sys-admin' || this.User_Auth == 'wf-corp-staff' || this.User_Auth == '' || this.User_Auth == 'wf-fc-head' || this.User_Auth ==  'wf-fc-head-limit'){
      if (this.Department_Shop == null || this.Department_Shop == '') {
        err = true;
        this.translate
          .get('ACCOUNT_CREATE_VALIDATION.ERROR_DEPARTMENT_NAME')
          .subscribe((res) => (errTxt = res));
        }
      }else{
      this.Department_Shop = '';
    }
    if (this.User_Auth == '' || this.User_Auth == null) {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_AUTHORITY')
        .subscribe((res) => (errTxt = res));
    }
    if (this.First_Name == null || this.First_Name == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_FIRST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.Last_Name == null || this.Last_Name == '') {
      err = true;
      this.translate
        .get('ACCOUNT_CREATE_VALIDATION.ERROR_LAST_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }
  back(id) {
    if(id == 1){
      this.router.navigate(['/wf-account-list-detail',{ uuid: this.uuid }]);
    }else{
      document.getElementById('error').style.display = 'none';
      document.getElementById('edit').style.display = 'block';
      document.getElementById('confirm').style.display = 'none';
    }
  }

  Next() {
    if (this.dataValidation() == false) {
      return true;
    } else {
      const body = {
        data:{
           user_uuid: this.data.uuid,
           uuid: this.uuid,
           user_last_name: this.Last_Name,
           user_first_name: this.First_Name,
           user_auth: this.User_Auth,
           mail_address: this.Mail_Address,
           department_name: this.Department_Shop,
           shop_name: this.StoreName,
           management_company_code: this.Management_Company_Code,
           limit: this.limit
        }
      };
      console_log(body);
      document.getElementById('confirm').style.display = 'block';
      document.getElementById('edit').style.display = 'none';
    }
  }

  finish() {
    this.spinner.show();
    if(this.User_Auth == 'wf-fc-head' &&  this.limit == 0){
      this.User_Auth = 'wf-fc-head-limit';
    }
    this.rest.Update_CognitoUser(
      this.data.uuid,
      this.uuid,
      this.Last_Name,
      this.First_Name,
      this.User_Auth,
      this.Mail_Address,
      this.Department_Shop,
      this.StoreName,
      this.Management_Company_Code).subscribe(
        (res) => {
          this.spinner.hide();
          console_log(res);
          this.router.navigate(['/wf-account-list-modi-comp']);
        },
        (err) => {
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
    );
  }
  
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;
          header.style.minWidth = width.toString() + 'px';
      }
  }
  validateEmail(mail) {
    var reg = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    return reg.test(mail);
  }
  searchLimit(i) {
    console_log(i);
    this.limit = i;
  }
}
