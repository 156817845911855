<div class="main-padding">
  <h3 class="header-bottom header-text1">{{ 'ANNOUNCEMENT.TITLE' | translate }}</h3>
  <div class="text-center">
    <div class="text-center finish">
      {{ 'ADD_ANNOUNCEMENT.FINISH_TEXT_1' | translate }}<br>{{ 'ADD_ANNOUNCEMENT.FINISH_TEXT_2' | translate }}
    </div>
    <div class="text-center finish2">
      {{ 'ADD_ANNOUNCEMENT.FINISH_TEXT_3' | translate }}
    </div>
    <div class="text-center">
      <div class="two-btn">
        <div class="display">
          <a (click)="notification()" class="btn-grey">{{ 'ADD_ANNOUNCEMENT.FINISH_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>     
</div>