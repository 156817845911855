import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { console_log, DataProvider } from 'src/providers/data/data';
import { CallapiService } from 'src/providers/rest/callapi.service';

@Component({
  selector: 'app-list-detail',
  templateUrl: './list-detail.component.html',
  styleUrls: ['./list-detail.component.scss'],
})
export class ListDetailComponent implements OnInit {
  public StartDate = '';
  public EndDate = '';
  public EndDate1 = '';
  public Mail = '';
  public Users = '';
  public Company_Number = '';
  public documentpdf = '';
  public uuid = '';
  public Corporation_Form = '';
  public Company_Name = '';
  public Company_Name_Kana = '';
  public Post_Code = '';
  public Address_1 = '';
  public Address_2 = '';
  public Address_3 = '';
  public TelePhone = '';
  public Last_Name = '';
  public Name = '';
  public Last_Name_Kana = '';
  public Name_Kana = '';
  public Staff_Post = '';
  public Staff_Position = '';
  public History_Flg = '';
  public Corporate_Status = '';
  public Corporation_No = '';
  public Acquired_Company_Code = '';
  public Acquired_Company_Name = '';
  public Application_Date = '';
  public Application_Time = '';
  public ContractsFLG = 0;

  public pages;
  public wf_contract_result_resend: boolean;
  public wf_contract_modify_edit: boolean;
  public wf_contract_list_detail: boolean;

  public Fees;
  public Fees1;
  public Fees2;
  public paid_form;
  public health_code = '';
  public Promo_Code1 = '';
  public Promo_Code2 = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public data: DataProvider,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    protected datepipe: DatePipe,
  ) {}

  ngOnInit() {
    this.data.menuPageTitle = 'wf-contract-list';
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.spinner.show();
    this.rest.Get_ApplicationsId(this.data.uuid, this.uuid).subscribe(
      (res) => {
        console_log(res);
        this.Corporate_Status = res.data.corporate_status;
        this.Corporation_Form = res.data.corporation_form;
        this.Company_Name = res.data.company_name;
        this.Company_Name_Kana = res.data.company_name_kana;
        this.Post_Code = res.data.postcode;
        this.Address_1 = res.data.address_1;
        this.Address_2 = res.data.address_2;
        this.Address_3 = res.data.address_3;
        this.TelePhone = res.data.tel;
        this.Last_Name = res.data.staff_last_name;
        this.Name = res.data.staff_first_name;
        this.Last_Name_Kana = res.data.staff_last_name_kana;
        this.Name_Kana = res.data.staff_first_name_kana;
        this.Staff_Post = res.data.staff_post;
        this.Staff_Position = res.data.staff_position;
        this.History_Flg = res.data.history_flg;
        this.Corporation_No = res.data.corporation_no;
        this.Acquired_Company_Code = res.data.acquired_code;
        this.Acquired_Company_Name = res.data.acquired_name;
        this.Application_Date = this.datepipe.transform(res.data.start_date, 'yyyy/MM/dd');
        this.EndDate = this.datepipe.transform(res.data.end_date, 'yyyy/MM/dd');
        this.Mail = res.data.mail_address;
        this.Application_Time = res.data.app_time;
        this.Users = res.data.users;
        this.ContractsFLG = res.data.contracts_flg;
        this.Fees1 = res.data.fees1;
        this.Fees2 = res.data.fees2;
        this.health_code = res.data.health_code;
        this.paid_form = res.data.paid_form;
        this.Promo_Code1 = res.data.promotion_code1;
        this.Promo_Code2 = res.data.promotion_code2;
        this.spinner.hide();
      },
      (err) => {
        console_log(err);
        this.spinner.hide();
        this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
      }
    );
    this.rest.Get_Screenauth(this.data.uuid).subscribe(
      (res) => {
        this.pages = res.data;
        for (let i = 0; i < this.pages.length; i++) {
          if (this.pages[i].pagename == 'wf-contract-list-detail') {
            console_log('wf-contract-list-detail');
            if (this.pages[i].auth == 'F') {
              this.wf_contract_list_detail = true;
            }
            if (this.pages[i].auth == 'R') {
              this.wf_contract_list_detail = false;
            }
          }
          if (this.pages[i].pagename == 'wf-contract-result-resend') {
            console_log('wf-contract-result-resend');
            if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
              this.wf_contract_result_resend = true;
            }
            if (this.pages[i].auth == 'D') {
              this.wf_contract_result_resend = false;
            }
          }
          if (this.pages[i].pagename == 'wf-contract-modify-edit') {
            console_log('wf-contract-modify-edit');
            if (this.pages[i].auth == 'F' || this.pages[i].auth == 'R') {
              this.wf_contract_modify_edit = true;
            }
            if (this.pages[i].auth == 'D') {
              this.wf_contract_modify_edit = false;
            }
          }
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    this.widthChanger('main-header', 752);
  }
  widthChanger(className, width) {
    const headers = document.getElementsByClassName(className);
        for (let i = 0; i < headers.length; i++) {
          const header = headers[i] as HTMLElement;

          header.style.minWidth = width.toString() + 'px';
      }
  }
  back() {
    this.router.navigate([this.data.backButton]);
  }
  Cancel() {
    this.router.navigate(['/wf-contract-list-cancel-conf',{ uuid: this.uuid }]);
  }
  send() {
    this.router.navigate(['/wf-contract-result-resend',{ uuid: this.uuid,}]);
  }
  editor() {
    this.router.navigate(['/wf-contract-modify-edit',{uuid: this.uuid}]);
  }
  pdf_download(){
    this.rest
      .Download_Regcertificates(this.data.uuid, this.uuid)
      .subscribe((res) => {
        if (res.code == 'S000001') {
          this.documentpdf = res.data.s3url;
          window.open(this.documentpdf, '_blank', '');
        }
      });
  }
  pdf_download_s3() {
    this.spinner.show();
    this.rest.Download_Contracts(this.data.uuid, this.uuid).subscribe(
        (res) => {
          if (res.code == 'S000001') {
            this.spinner.hide();
            window.open(res.data.s3url, '_blank', '');
          }
        },
        (err) => {
          console_log(err);
          this.spinner.hide();
          this.router.navigate(['/wf-contract-err', {code: err.error.error.code}]);
        }
      );
  }
}
